import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import Card from 'react-bootstrap/Card';
// import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PayCheck from '../images/payment-check.png';
import PayX from '../images/payment-x.png';
import LogoutLink from './logout-link';
import '../styles/account-options.css';

const cookies = new Cookies();

class AccountOptions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            windowWidth: window.outerWidth,
            currentPage: window.location.pathname,
            isLoggedIn: false
        }

        this.setLimitedPayment = this.setLimitedPayment.bind(this)
        this.setFullPayment = this.setFullPayment.bind(this)
    }

    componentDidMount() {
        cookies.remove('paymentPlan', { path: '/' })

        if (cookies.get('inCookie') === 'in') { this.setState({ isLoggedIn: true }) }
    }

    setLimitedPayment() {
        cookies.set('paymentPlan', 'limited', { path: '/' })

        window.location.href = "/checkout"
    }

    setFullPayment() {
        cookies.set('paymentPlan', 'unlimited', { path: '/' })

        window.location.href = "/checkout"
    }

    render() {
        const { windowWidth, currentPage } = this.state

        return (
            <React.Fragment>
                <LogoutLink windowWidth={windowWidth} currentPage={currentPage} isLoggedIn={this.state.isLoggedIn} />
                <div style={{ marginTop: '2rem' }}>
                    <h1 style={{ width: '62.5%', margin: 'auto', paddingLeft: '1.5rem', fontSize: '1.5rem' }}>CHOOSE AN OPTION:</h1>
                    <Row style={{ width: '62.5%', margin: '2.5rem auto' }}>
                        <Col style={{ paddingLeft: '1.5rem' }}>
                            <Card id="payment-card-free" onClick={() => window.location.href = '/calculators/savings-bond-calculator-free'} className="payment-card" style={{ position: 'relative', minHeight: '26.5rem', borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                                <Card.Title style={{ textAlign: 'center', padding: '1rem 0', borderBottom: '1px solid rgba(0,0,0,.125)' }}>LIMITED ACCESS</Card.Title>
                                <Card.Subtitle style={{ marginTop: '1.5rem', fontSize: '2rem', textAlign: 'center' }}>
                                    No Cost To You
                                </Card.Subtitle>
                                <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                                    <p style={{ fontSize: '1.15rem', fontWeight: '500', color: 'red' }}>FREE</p>
                                </div>
                                <div style={{ padding: '1rem' }}>
                                    <Table style={{ marginTop: '1rem' }}>
                                        <tbody>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayCheck} />
                                                    <span style={{ marginLeft: '.5rem' }}>Individually value your savings bonds</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayX} />
                                                    <span style={{ marginLeft: '.5rem' }}><b>Reveal/Unblur</b> your submitted savings bonds</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayX} />
                                                    <span style={{ marginLeft: '.5rem' }}>Build/save spreadsheets online</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayX} />
                                                    <span style={{ marginLeft: '.5rem' }}>Import/Export to and from spreadsheet</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayX} />
                                                    <span style={{ marginLeft: '.5rem' }}>Filter mature bonds only</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayX} />
                                                    <span style={{ marginLeft: '.5rem' }}>No advertisements</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                            <Button
                                id="payment-card-button-free"
                                onClick={() => window.location.href = '/calculators/savings-bond-calculator-free'}
                                style={{ width: '100%', padding: '1rem 0', borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: '-1px', fontWeight: 'bold', fontSize: '1.1rem', background: "#4A90E2" }}>
                                I WANT LIMITED ACCESS
                            </Button>
                        </Col>
                        <Col style={{ paddingLeft: '1.5rem' }}>
                            <Card id="payment-card" onClick={this.setFullPayment} className="payment-card" style={{ position: 'relative', minHeight: '26.5rem', borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                                {/* <div style={{ position: 'absolute', top: '-2.5rem', color: "red", fontSize: '1.2rem', fontWeight: 'bold' }}>* LIMITED TIME OFFER - LIFETIME ACCESS *</div> */}
                                <Card.Title style={{ textAlign: 'center', padding: '1rem 0', borderBottom: '1px solid rgba(0,0,0,.125)' }}>FULL ACCESS</Card.Title>
                                <Card.Subtitle style={{ marginTop: '1.5rem', fontSize: '2rem', textAlign: 'center' }}>
                                    Pay What You Feel Is Right
                                </Card.Subtitle>
                                <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                                    <p style={{ fontSize: '1.15rem', fontWeight: '500', color: 'red' }}>Minimum $1.00</p>
                                </div>
                                <div style={{ padding: '1rem' }}>
                                    <Table style={{ marginTop: '1rem' }}>
                                        <tbody>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayCheck} />
                                                    <span style={{ marginLeft: '.5rem' }}><b>Reveal/Unblur</b> your submitted savings bonds</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayCheck} />
                                                    <span style={{ marginLeft: '.5rem' }}>Build/save spreadsheets online</span>
                                                </td>
                                                {/* <td className="payment-card-td">
                                                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>Unlimited</p>
                                                </td> */}
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayCheck} />
                                                    <span style={{ marginLeft: '.5rem' }}>Import from spreadsheet</span>
                                                </td>
                                                {/* <td className="payment-card-td">
                                                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>Unlimited</p>
                                                </td> */}
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayCheck} />
                                                    <span style={{ marginLeft: '.5rem' }}>Export results to spreadsheet</span>
                                                </td>
                                                {/* <td className="payment-card-td">
                                                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>Unlimited</p>
                                                </td> */}
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayCheck} />
                                                    <span style={{ marginLeft: '.5rem' }}>Filter mature bonds only</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="payment-card-td">
                                                    <Image style={{ width: '1.5rem', marginTop: '-.2rem' }} src={PayCheck} />
                                                    <span style={{ marginLeft: '.5rem' }}>No advertisements</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                            <Button
                                id="payment-card-button"
                                onClick={this.setFullPayment}
                                style={{ width: '100%', padding: '1rem 0', borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: '-1px', fontWeight: 'bold', fontSize: '1.1rem', background: "#4A90E2" }}>
                                I WANT FULL ACCESS
                            </Button>
                        </Col>
                    </Row>
                </div>
            </React.Fragment >
        );
    }
}

export default AccountOptions;