import { isMobile } from "react-device-detect";

function setClass(name) {
    let windowWidth

    if (window.outerWidth === 0) {
        windowWidth = window.innerWidth
    }
    else {
        windowWidth = window.outerWidth
    }

    if (isMobile) {
        if (windowWidth < 450) {
            return name + '-mobile-sm'
        }
        else if (windowWidth < 1024) {
            return name + '-mobile'
        }
        else {
            return name + '-tablet'
        }
    }
    else {
        return name
    }
};

export default setClass;