import React, { Component } from 'react';
import { readString } from 'react-papaparse'
import readXlsxFile from 'read-excel-file';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class ImportSpreadsheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.importBonds = this.importBonds.bind(this)
    }

    getSeries(currentRow) {
        let x = 0

        let series
        let seriesIndex
        let seriesMap = ['N', 'I', 'E', 'EE']

        while (x < currentRow.length) {
            let currentCell = currentRow[x]

            if (typeof currentCell === 'string') {
                let obj = currentCell.split(" ")

                for (let o in obj) {
                    let s = obj[o].toUpperCase().trim();

                    if (seriesMap.includes(s)) {
                        series = s
                        seriesIndex = x
                    }
                }
            }

            x++
        }

        let results = [series, seriesIndex]

        return results
    }

    getDenomination(currentRow) {
        let x = 0

        let denominations = [10, 25, 50, 75, 100, 200, 500, 1000, 5000, 10000]

        let denomination
        let denominationIndex

        while (x < currentRow.length) {
            let currentCell = currentRow[x]

            if (denominations.includes(currentCell)) {
                denomination = currentCell
                denominationIndex = x
            }
            else if (typeof currentCell === 'string') {
                let parseNumbers = /^[0-9]+$/;
                let parseCell = currentCell.replace(/[$,]|[.][0-9][0-9]/g, "").trim()

                if (parseCell.match(parseNumbers)) {
                    if (denominations.includes(parseInt(parseCell))) {
                        denomination = parseCell
                        denominationIndex = x
                    }
                }
            }

            x++
        }

        let results = [denomination, denominationIndex]

        return results
    }

    getIssueDate(currentRow) {
        const d = new Date();
        const tMonth = {
            'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6, 'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12,
            'January': 1, 'February': 2, 'March': 3, 'April': 4, 'June': 6, 'July': 7, 'August': 8, 'September': 9, 'October': 10, 'November': 11, 'December': 12
        }

        let x = 0

        let issueMonth
        let issueYear
        let issueDateIndex

        while (x < currentRow.length) {
            let currentCell = currentRow[x]

            if (typeof currentCell === 'object') {
                let issueDate = new Date(currentCell)

                issueMonth = issueDate.getMonth() + 1
                issueYear = issueDate.getFullYear()

                issueDateIndex = x
            }
            else if (typeof currentCell === 'string') {
                let parseDate = currentCell.split(/[,]|[-]|[/]/)
                let parseMonth = tMonth[parseDate[0]]

                if (
                    (parseDate.length - 1 > 0) &&
                    (parseMonth || (parseInt(parseDate[0]) > 0 && parseInt(parseDate[0]) <= 12)) &&
                    (parseInt(parseDate[parseDate.length - 1]) >= 0 && parseInt(parseDate[parseDate.length - 1]) <= d.getFullYear())
                ) {
                    if ((parseDate.length - 1) === 1) {
                        if (tMonth[parseDate[0]]) {
                            issueMonth = tMonth[parseDate[0]]
                        }
                        else if (parseInt(parseDate[0]) > 0 && parseInt(parseDate[0]) <= 12) {
                            issueMonth = parseInt(parseDate[0])
                        }

                        issueYear = parseInt(parseDate[parseDate.length - 1])

                        if (issueYear >= 0 && issueYear <= (d.getFullYear() - 2000)) {
                            issueYear = issueYear + 2000
                        }
                        else if (issueYear < 100) {
                            issueYear = issueYear + 1900
                        }
                    }
                    else if ((parseDate.length - 1) === 2) {
                        if (tMonth[parseDate[0]]) {
                            issueMonth = tMonth[parseDate[0]]
                        }
                        else if (parseInt(parseDate[0]) > 0 && parseInt(parseDate[0]) <= 12) {
                            issueMonth = parseInt(parseDate[0])
                        }

                        issueYear = parseInt(parseDate[parseDate.length - 1])

                        if (issueYear >= 0 && issueYear <= (d.getFullYear() - 2000)) {
                            issueYear = issueYear + 2000
                        }
                        else if (issueYear < 100) {
                            issueYear = issueYear + 1900
                        }
                    }

                    issueDateIndex = x
                }
            }

            x++
        }

        let results = [issueMonth, issueYear, issueDateIndex]

        return results
    }

    getSerialNumber(currentRow) {
        let serialNumber
        let i = [0, 1, 2, 3]

        let idx = [this.getSeries(currentRow)[1], this.getDenomination(currentRow)[1], this.getIssueDate(currentRow)[2]]

        let n = i.filter(x => !idx.includes(x))

        if (n.length === 1) {
            serialNumber = currentRow[n[0]]
        }

        return serialNumber
    }

    importBonds(e) {
        let bondTABLE = document.getElementById("bond-table")
        let newTBODY = document.createElement('tbody')

        bondTABLE.replaceChild(newTBODY, bondTABLE.getElementsByTagName('tbody')[0])

        if (e.target.files.length > 0) {
            let fileType = (e.target.files[0].name).split('.')[1]
            this.setState({ label: e.target.files[0].name })

            if (e.target.files[0].name !== this.state.label) {
                this.setState({ currentPageValue: 0 })
            }

            if (fileType === 'xlsx') {
                readXlsxFile(e.target.files[0]).then((rows) => {


                    for (let i = 0; i < rows.length; i++) {
                        let filteredRows = rows[i].filter(function (item) {
                            let result

                            if (item !== null) {
                                result = item
                            }

                            return result
                        });

                        let serialNumber = ""
                        let series = this.getSeries(filteredRows)[0]
                        let denomination = this.getDenomination(filteredRows)[0]
                        let issueMonth = this.getIssueDate(filteredRows)[0]
                        let issueYear = this.getIssueDate(filteredRows)[1]

                        if ((filteredRows.length) === 4) {
                            serialNumber = this.getSerialNumber(filteredRows)
                        }

                        if (series && denomination && issueMonth && issueYear) {

                            this.props.addTableRow(serialNumber, series, denomination, issueMonth, issueYear)
                        }
                    }
                });
            }
            else if (fileType === 'csv') {
                let reader = new FileReader();

                reader.onload = () => {
                    let rows = readString(reader.result)['data']

                    for (let i = 0; i < rows.length; i++) {
                        let serialNumber = ""
                        let series = this.getSeries(rows[i])[0]
                        let denomination = this.getDenomination(rows[i])[0]
                        let issueMonth = this.getIssueDate(rows[i])[0]
                        let issueYear = this.getIssueDate(rows[i])[1]

                        if ((rows[i].length) === 4) {
                            serialNumber = this.getSerialNumber(rows[i])
                        }

                        if (series && denomination && issueMonth && issueYear) {
                            this.props.addTableRow(serialNumber, series, denomination, issueMonth, issueYear)
                        }
                    }
                }

                reader.readAsText(e.target.files[0]);
            }
        }
    }

    importBondsOnClick(e) {
        document.getElementById('import-bonds').value = ""
    }

    render() {
        return (
            <React.Fragment>
                <input
                    id="import-bonds"
                    type="file"
                    ref={(ref) => this.inputClick = ref}
                    style={{ display: 'none' }}
                    onChange={this.importBonds}
                    onClick={this.importBondsOnClick}
                >
                </input>
                <OverlayTrigger
                    placement='bottom'
                    overlay={<Tooltip style={{ opacity: .75 }}>Import spreadsheet (xlsx or csv)</Tooltip>}
                    delay={{ show: 500, hide: 0 }}
                >
                    <Button
                        type="sm"
                        variant="dark"
                        onClick={() => this.inputClick.click()}
                        style={{ fontSize: '.75rem', fontWeight: '500', marginLeft: '.75rem', padding: '.25rem .5rem' }}
                    >
                        IMPORT BONDS
                </Button>
                </OverlayTrigger>
            </React.Fragment >
        );
    }
}

export default ImportSpreadsheet;