import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player';
import Cookies from 'universal-cookie';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PlusSign from '../../images/Plus.png';
import ImportSpreadsheet from './import-spreadsheet';
import LogoutLink from '../logout-link';
import BondTable from "./bond-table";
import "../../styles/online-spreadsheet.css";

const cookies = new Cookies();

class OnlineSpreadsheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bonds: [],
            filteredBonds: [],
            errors: [],
            rowCount: 0,
            totalPrice: 0,
            totalInterest: 0,
            totalValue: 0,
            totalDenom: 0,
            totalDate: this.formatDate(this.getCurrentMonth() + '/' + this.getCurrentYear()),
            currentMonth: this.getCurrentMonth(),
            currentYear: this.getCurrentYear(),
            addButtonClass: 'add-image',
            windowWidth: window.outerWidth,
            currentPage: window.location.pathname,
            denominationOptions: [50, 75, 100, 200, 500, 1000, 5000, 10000],
            pages: [],
            perPage: 10,
            currentPageValue: 0,
            valueMonth: this.getCurrentMonth(),
            valueYear: this.getCurrentYear(),
            importableCsv: false,
            activeTab: 'build-spreadsheet',
            showModal: false,
            showCancelModal: false,
            showTutorialModal: false,
            saveDisabled: true,
            savedWorkList: [],
            valuedWidth: null,
            isLoggedIn: false,
            database: null,
            hasFullAccess: false
        };

        this.handleResize = this.handleResize.bind(this)
        this.onEnterAddButton = this.onEnterAddButton.bind(this)
        this.onLeaveAddButton = this.onLeaveAddButton.bind(this)
        this.addTableRow = this.addTableRow.bind(this)
        this.setTotals = this.setTotals.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.setPrevPage = this.setPrevPage.bind(this)
        this.setCurrentPage = this.setCurrentPage.bind(this)
        this.setNextPage = this.setNextPage.bind(this)
        this.setPerPage = this.setPerPage.bind(this)
        this.setPages = this.setPages.bind(this)
        this.sendBonds = this.sendBonds.bind(this)
        this.setActiveTab = this.setActiveTab.bind(this)
        this.onSaveClick = this.onSaveClick.bind(this)
        this.saveDataName = this.saveDataName.bind(this)
        this.getSavedData = this.getSavedData.bind(this)
        this.onSeriesChange = this.onSeriesChange.bind(this)
        this.resetTable = this.resetTable.bind(this)
        this.setShowMatured = this.setShowMatured.bind(this)
        this.setCardTotals = this.setCardTotals.bind(this)
        this.getFullAccess = this.getFullAccess.bind(this)
        this.onFreeCalculatorClick = this.onFreeCalculatorClick.bind(this)
    }

    getFullAccess() {
        localStorage.setItem("getFullAccess", "true")
        localStorage.setItem("savedBonds", JSON.stringify(this.savedBonds()));

        if (this.state.isLoggedIn) {
            window.location.href = "/account-options"
        }
        else {
            window.location.href = "/login"
        }
    }

    onFreeCalculatorClick() {
        if (cookies.get('inCookie') === "in") {
            window.location.href = "/calculators/savings-bond-calculator-free"
        }
        else {
            window.location.href = "/login"
        }
    }

    setCardTotals(bonds) {
        let denTotal = [];
        let cosTotal = [];
        let intTotal = [];
        let valTotal = [];

        for (let i = 0; i < bonds.length; i++) {
            denTotal.push(bonds[i]['denomination'])
            cosTotal.push(bonds[i]['issuePrice'])
            intTotal.push(bonds[i]['interest'])
            valTotal.push(bonds[i]['value'])
        }

        this.setState({
            totalDenom: denTotal.reduce(function (a, v) { return a + v; }, 0),
            totalPrice: cosTotal.reduce(function (a, v) { return a + v; }, 0),
            totalInterest: intTotal.reduce(function (a, v) { return a + v; }, 0),
            totalValue: valTotal.reduce(function (a, v) { return a + v; }, 0)
        });
    }

    setShowMatured(e) {
        const { bonds } = this.state

        if (e.target.checked) {
            let filteredBonds = bonds.filter(bond => bond['accrualDate'] === 'Matured')

            this.setState({ filteredBonds: filteredBonds })
            this.setCardTotals(filteredBonds)
            this.setPages(filteredBonds, this.state.perPage)

        }
        else {
            this.setState({ filteredBonds: bonds })
            this.setCardTotals(bonds)
            this.setPages(bonds, this.state.perPage)
        }
    }

    resetTable() {
        this.setState({ showCancelModal: false })

        let bondTABLE = document.getElementById("bond-table")
        let newTBODY = document.createElement('tbody')

        bondTABLE.replaceChild(newTBODY, bondTABLE.getElementsByTagName('tbody')[0])

        this.addTableRow(null, "EE", 50, 5, 2000)
    }

    onSaveClick() {
        this.setState({ showModal: true });
        localStorage.setItem("savedBonds", JSON.stringify(this.savedBonds()));
    }

    componentDidMount() {
        let localBonds = localStorage.getItem('savedBonds');

        if (cookies.get('inCookie') === 'in') {
            this.setState({
                isLoggedIn: true,
                database: cookies.get('userEmail') + "_" + cookies.get('userType')
            })
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ 'email': cookies.get('userEmail'), 'accountType': cookies.get('userType') })
        };

        //http://localhost:5000/check-subscription
        //https://secure.savingsbondsolutions.com/check-subscription

        fetch('https://secure.savingsbondsolutions.com/check-subscription', requestOptions)
            .then(response => response.json())
            .then(response => {
                if (response['accessLevel'] === 1) {
                    this.setState({ hasFullAccess: true })
                }
            });

        window.addEventListener("resize", this.handleResize);

        if (window.outerWidth > 0 && window.outerHeight > 0) {
            this.setState({ windowWidth: window.outerWidth })
        }
        else {
            this.setState({ windowWidth: window.innerWidth })
        }

        const { database } = this.state

        if (database) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({ 'name': database })
            };

            //http://localhost:5000/get-existing-saved-data
            //https://secure.savingsbondsolutions.com/get-existing-saved-data

            fetch('https://secure.savingsbondsolutions.com/get-existing-saved-data', requestOptions)
                .then(response => response.json())
                .then(response => {
                    this.setState({ savedWorkList: response['saved_work_history'] })
                });
        }

        if (!localBonds) {
            this.addTableRow(null, "EE", 50, 5, 2000)

            this.setState({ valuedWidth: document.getElementsByName("serialNumber")[0].offsetWidth });
        }
        else {
            let savedBonds = JSON.parse(localBonds);

            for (let i = 0; i < savedBonds.length; i++) {
                this.addTableRow(
                    savedBonds[i]['serialNumber'],
                    savedBonds[i]['series'],
                    savedBonds[i]['denomination'],
                    savedBonds[i]['issueMonth'],
                    savedBonds[i]['issueYear']
                )
            }

            document.getElementsByName('valueMonth')[0].value = savedBonds[0]['valueMonth']
            document.getElementsByName('valueYear')[0].value = savedBonds[0]['valueYear']
        }

        localStorage.removeItem("savedBonds")
    }

    handleResize() {
        setTimeout(() => {
            if (window.outerWidth > 0 && window.outerHeight > 0) {
                this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight })
            }
            else {
                this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
            }

            this.setState({ valuedWidth: document.getElementsByName("serialNumber")[0].offsetWidth });
        }, 25);
    }

    savedBonds() {
        let submitBonds = []
        let rows = document.getElementsByTagName('tbody')[0].getElementsByTagName('tr')

        let valueMonth = document.getElementsByName('valueMonth')[0].value
        let valueYear = document.getElementsByName('valueYear')[0].value

        for (let i = 0; i < rows.length; i++) {
            let serialNumber = document.getElementsByName('serialNumber')[i].value
            let series = document.getElementsByName('series')[i].value
            let denomination = document.getElementsByName('denomination')[i].value
            let issueMonth = document.getElementsByName('issueMonth')[i].value
            let issueYear = document.getElementsByName('issueYear')[i].value

            let submitBond = {
                'valueMonth': parseInt(valueMonth),
                'valueYear': parseInt(valueYear),
                'serialNumber': serialNumber,
                'series': series,
                'denomination': parseInt(denomination),
                'issueMonth': parseInt(issueMonth),
                'issueYear': parseInt(issueYear)
            }

            submitBonds.push(submitBond)
        }

        return submitBonds
    }

    setTotals(denomination, price, interest, value) {
        this.setState({
            totalDenom: this.state.totalDenom - denomination,
            totalPrice: this.state.totalPrice - price,
            totalInterest: this.state.totalInterest - interest,
            totalValue: this.state.totalValue - value
        })
    }

    formatDate(value_date) {
        const months = { "01": "Jan.", "02": "Feb.", "03": "Mar.", "04": "Apr.", "05": "May", "06": "June", "07": "July", "08": "Aug.", "09": "Sept.", "10": "Oct.", "11": "Nov.", "12": "Dec." }

        let month = value_date.split('/')[0]

        if (month.length === 1) {
            month = '0' + month
        }

        let year = value_date.split('/')[1]

        let month_string = months[month] + ' ' + year

        return month_string
    }

    convertValues(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    getCurrentMonth() {
        let d = new Date();
        return d.getMonth() + 1
    }

    getCurrentYear() {
        let d = new Date();

        return d.getFullYear()
    }

    setPages(bonds, perPage) {
        let pages = []
        let pageCount = Math.ceil(bonds.length / perPage)

        let i = 0
        while (i < pageCount) {
            pages.push(i)

            i++
        }

        this.setState({ pages: pages })
    }

    setCurrentPage(i) {
        this.setState({ currentPageValue: i })
    }

    setPrevPage() {
        let showCount = (this.state.currentPageValue - 1) * this.state.perPage
        if (showCount >= 0) {
            this.setState({ currentPageValue: this.state.currentPageValue - 1 })
        }
    }

    setNextPage() {
        let showCount = (this.state.currentPageValue + 1) * this.state.perPage
        if (showCount < this.state.bonds.length) {
            this.setState({ currentPageValue: this.state.currentPageValue + 1 })
        }
    }

    setPerPage(e) {
        e.preventDefault()

        let rowCount = parseInt(e.target.value)

        if (rowCount > 0) {
            this.setPages(this.state.bonds, rowCount);

            this.setState({ currentPageValue: 0, perPage: rowCount })
        }
    }

    onEnterAddButton() {
        this.setState({ addButtonClass: 'add-image-inverted' })
    }

    onLeaveAddButton() {
        this.setState({ addButtonClass: 'add-image' })
    }

    getDefaultIndex(options, value) {
        let index = null

        for (let k = 0; k < options.length; k++) {
            if (options[k].value === value.toString()) {
                index = options[k].index
            }
        }

        if (!index) { index = 0 }

        return index
    }

    getDenominations(series) {
        let denominations

        if (series === "EE") { denominations = [50, 75, 100, 200, 500, 1000, 5000, 10000] }
        else if (series === "I") { denominations = [25, 50, 75, 100, 200, 500, 1000, 5000, 10000] }
        else if (series === "E") { denominations = [10, 25, 50, 75, 100, 200, 500, 1000, 5000, 10000] }
        else if (series === "S") { denominations = [25, 50, 75, 100] }

        return denominations
    }

    removeTableRow(e) {
        document.getElementById((e.target.id).replace("remove", "bond")).remove()
    }

    onSeriesChange(e) {
        let series = e.target.value
        let denELEMENT = document.getElementById(e.target.id.replace("series", "denomination"))

        denELEMENT.options.length = 0

        let denominations = this.getDenominations(series)

        for (let i = 0; i < denominations.length; i++) {
            let OPTION = document.createElement('option')
            OPTION.text = "$" + denominations[i].toString()
            OPTION.value = denominations[i]

            denELEMENT.appendChild(OPTION)
        }

        denELEMENT.selectedIndex = this.getDefaultIndex(denELEMENT.options, 50)
    }

    addTableRow(snDefault, sDefault, dDefault, mDefault, yDefault) {
        let TBODY = document.getElementById('bond-table').getElementsByTagName('tbody')[0]
        let rowCount = TBODY.getElementsByTagName('tr').length

        let TR = document.createElement('tr')
        TR.id = "bond-table-row-" + rowCount.toString()

        let i = 0
        while (i < 4) {
            let TD = document.createElement('td')
            TD.className = "bond-table-td"

            let INPUT, SELECT

            if (i === 0) {
                let mDIV = document.createElement('div')
                mDIV.className = "input-group input-group-sm"

                let mINPUT = document.createElement('input')
                mINPUT.name = "issueMonth"
                mINPUT.className = "form-control"
                mINPUT.setAttribute("type", "number")
                mINPUT.style.flex = .5
                mINPUT.value = 5
                mINPUT.min = 1
                mINPUT.max = 12
                mINPUT.maxLength = 2
                mINPUT.value = mDefault

                let sDIV = document.createElement('div')
                sDIV.className = "input-group-append"

                let sSPAN = document.createElement('span')
                sSPAN.className = "input-group-text"
                sSPAN.innerHTML = "/"

                sDIV.appendChild(sSPAN)

                let yINPUT = document.createElement('input')
                yINPUT.name = "issueYear"
                yINPUT.className = "form-control"
                yINPUT.setAttribute("type", "number")
                yINPUT.style.marginLeft = '-1px'
                yINPUT.value = 2000
                yINPUT.max = this.state.currentYear
                yINPUT.maxLength = 4
                yINPUT.value = yDefault

                mDIV.appendChild(mINPUT)
                mDIV.appendChild(sDIV)
                mDIV.appendChild(yINPUT)

                TD.appendChild(mDIV)
            }
            else if (i === 1) {
                let series = { "EE": "EE Bond", "I": "I Bond", "E": "E Bond", "S": "Savings Note" }

                SELECT = document.createElement('select')
                SELECT.id = "series-" + rowCount.toString()
                SELECT.className = "custom-select custom-select-sm"
                SELECT.name = "series"
                SELECT.onchange = this.onSeriesChange

                for (let key in series) {
                    let OPTION = document.createElement('option')
                    OPTION.text = series[key]
                    OPTION.value = key

                    SELECT.appendChild(OPTION)
                }

                SELECT.selectedIndex = this.getDefaultIndex(SELECT.options, sDefault)

                TD.appendChild(SELECT)
            }
            else if (i === 2) {
                SELECT = document.createElement('select')
                SELECT.id = "denomination-" + rowCount.toString()
                SELECT.className = "custom-select custom-select-sm"
                SELECT.name = "denomination"

                let denominations = this.getDenominations(sDefault)

                for (let j = 0; j < denominations.length; j++) {
                    let OPTION = document.createElement('option')
                    OPTION.text = "$" + denominations[j].toString()
                    OPTION.value = denominations[j]

                    SELECT.appendChild(OPTION)
                }

                SELECT.selectedIndex = this.getDefaultIndex(SELECT.options, dDefault)

                TD.appendChild(SELECT)
            }
            else if (i === 3) {
                TD.style.position = 'relative'

                INPUT = document.createElement('input')
                INPUT.className = "form-control form-control-sm"
                INPUT.name = "serialNumber"
                INPUT.placeholder = "Optional"
                INPUT.setAttribute("type", "text")
                INPUT.value = snDefault

                TD.appendChild(INPUT)

                if (rowCount > 0) {
                    let IMG = document.createElement('img')
                    IMG.id = "remove-table-row-" + rowCount.toString()
                    IMG.className = "row-red-cross"
                    IMG.src = "/static/media/red-cross.9d685c65.png"
                    IMG.onclick = this.removeTableRow

                    TD.appendChild(IMG)
                }
            }

            TR.appendChild(TD)

            i++
        }

        TBODY.appendChild(TR)
    }

    sendBonds(activeTab) {
        let requestOptions
        let submitBonds = this.savedBonds();

        localStorage.setItem("savedBonds", JSON.stringify(this.savedBonds()));

        let checkbox = document.getElementById("filter-matured")
        if (checkbox) { checkbox.checked = false }


        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ 'database': this.state.database, 'click': 'Submit' })
        };

        //http://localhost:5000/record-click
        //https://secure.savingsbondsolutions.com/record-click

        fetch('https://secure.savingsbondsolutions.com/record-click', requestOptions)

        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ submitBonds })
        };

        //http://localhost:5000/update
        //https://secure.savingsbondsolutions.com/update

        fetch('https://secure.savingsbondsolutions.com/update', requestOptions)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    bonds: response[0],
                    filteredBonds: response[0],
                    totalDate: this.formatDate(response[1]['totalDate']),
                    totalDenom: response[1]['totalDenoms'],
                    totalPrice: response[1]['totalPrices'],
                    totalInterest: response[1]['totalInterests'],
                    totalValue: response[1]['totalValues'],
                    errors: response[2],
                    activeTab: activeTab
                })

                this.setPages(response[0], this.state.perPage)
            });
    }

    setActiveTab(key) {
        if (key === 'build-spreadsheet') {
            this.setState({ activeTab: 'build-spreadsheet' })
        }
        else if (key === 'view-results') {
            this.setState({ activeTab: 'view-results' })
        }
    }

    saveDataName() {
        this.setState({ showModal: false });

        let bonds = [];
        let table = document.getElementById('save-data-name').value;

        let rows = document.getElementsByTagName('tbody')[0].getElementsByTagName('tr')

        let valueMonth = document.getElementsByName('valueMonth')[0].value
        let valueYear = document.getElementsByName('valueYear')[0].value

        for (let i = 0; i < rows.length; i++) {
            let serialNumber = document.getElementsByName('serialNumber')[i].value
            let series = document.getElementsByName('series')[i].value
            let denomination = document.getElementsByName('denomination')[i].value
            let issueMonth = document.getElementsByName('issueMonth')[i].value
            let issueYear = document.getElementsByName('issueYear')[i].value

            let bond = {
                'valueMonth': parseInt(valueMonth),
                'valueYear': parseInt(valueYear),
                'serialNumber': serialNumber,
                'series': series,
                'denomination': parseInt(denomination),
                'issueMonth': parseInt(issueMonth),
                'issueYear': parseInt(issueYear)
            }

            bonds.push(bond)
        }

        let user_data = { 'saved': [table.toString(), this.state.database, bonds] }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ user_data })
        };

        //http://localhost:5000/save-user-data
        //https://secure.savingsbondsolutions.com/save-user-data

        fetch('https://secure.savingsbondsolutions.com/save-user-data', requestOptions)
            .then(response => response.json())
            .then(response => {
                const { savedWorkList } = this.state

                let newSavedWorkList = []

                for (let i = 0; i < savedWorkList.length; i++) {
                    let savedWorkItem = savedWorkList[i]

                    newSavedWorkList.push(savedWorkItem)
                }

                if (!newSavedWorkList.includes(response['saved_name'])) {
                    newSavedWorkList.push(response['saved_name'])
                }

                this.setState({ savedWorkList: newSavedWorkList })
            });
    }

    getSavedData(e) {
        let savedName = e.target.innerText

        let bondTABLE = document.getElementById("bond-table")
        let newTBODY = document.createElement('tbody')

        bondTABLE.replaceChild(newTBODY, bondTABLE.getElementsByTagName('tbody')[0])

        let saved_data = {
            'database': this.state.database,
            'table': savedName.toString()
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ saved_data })
        };

        //http://localhost:5000/get-saved-bonds
        //https://secure.savingsbondsolutions.com/get-saved-bonds

        fetch('https://secure.savingsbondsolutions.com/get-saved-bonds', requestOptions)
            .then(response => response.json())
            .then(response => {
                let bonds = response['bonds']

                for (let i = 0; i < bonds.length; i++) {
                    this.addTableRow(
                        bonds[i]['serialNumber'],
                        bonds[i]['series'],
                        bonds[i]['denomination'],
                        bonds[i]['issueMonth'],
                        bonds[i]['issueYear']
                    )
                }

                document.getElementsByName('valueMonth')[0].value = response['valueMonth']
                document.getElementsByName('valueYear')[0].value = response['valueYear']

                this.sendBonds('build-spreadsheet')
            });
    }

    render() {
        const { windowWidth, currentPage, activeTab, addButtonClass, showModal, showCancelModal, showTutorialModal, saveDisabled, savedWorkList, hasFullAccess } = this.state

        return (
            <React.Fragment>
                <LogoutLink windowWidth={windowWidth} currentPage={currentPage} savedBonds={this.savedBonds} isLoggedIn={this.state.isLoggedIn} hasFullAccess={this.state.hasFullAccess} />
                <h1 style={{ display: "none" }}>Savings Bond Calculator</h1>
                <h2 style={{ display: "none" }}>Calculate the value of all your US savings bonds. Insert them individually or import them from a spreadsheet.</h2>
                <div id="bond-container" style={{ position: 'relative', width: '82.5%', margin: 'auto', padding: '2.5rem 0 .5rem 0' }}>
                    <Tabs className="build-table" defaultActiveKey="build-spreadsheet" activeKey={activeTab} onSelect={this.setActiveTab}>
                        <Tab eventKey="build-spreadsheet" title="Build Table">
                            <div id="tab-container" style={savedWorkList.length > 0 ? { width: '95%' } : { width: '75%' }}>
                                <Row style={{ position: 'absolute', top: '-3.5rem', right: '.75rem' }}>
                                    <p style={{ margin: '1.25rem 0 0 0', fontSize: '.75rem', fontWeight: 'bold' }}>VALUED AS OF</p>
                                    <div style={savedWorkList.length > 0 ? { width: '12rem', marginLeft: '1rem', marginRight: '.75rem' } : { width: '12.5rem', marginLeft: '1rem', marginRight: '.75rem' }}>
                                        <InputGroup size='sm' style={{ paddingTop: '.75rem' }}>
                                            <Form.Control
                                                required
                                                name="valueMonth"
                                                type="number"
                                                placeholder="Mo"
                                                min="1"
                                                max="12"
                                                defaultValue={this.state.currentMonth}
                                                style={{ flex: .6 }}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>/</InputGroup.Text>
                                            </InputGroup.Append>
                                            <Form.Control
                                                required
                                                name="valueYear"
                                                type="number"
                                                placeholder="Year"
                                                maxLength="4"
                                                min="1992"
                                                max={this.state.currentYear}
                                                defaultValue={this.state.currentYear}
                                                style={{ marginLeft: '-2px' }}
                                            />
                                        </InputGroup>
                                    </div>
                                </Row>
                                <Row>
                                    {savedWorkList.length > 0 ?
                                        <Col style={{ paddingRight: '2rem', borderRight: '1px solid #dee2e6' }}>
                                            <div style={{ marginTop: '.25rem' }}>
                                                <p style={{ fontSize: '.75rem', fontWeight: 'bold' }}>SAVED WORK</p>
                                                <ListGroup>
                                                    {savedWorkList.map((savedWork) => (
                                                        <ListGroup.Item key={savedWork} style={{ padding: '.25rem .75rem' }}>
                                                            <Button variant="link" onClick={this.getSavedData} style={{ padding: 0, textDecoration: 'none', fontSize: '.875rem', fontWeight: '500' }}>
                                                                {savedWork}
                                                            </Button>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </div>
                                        </Col> : null}
                                    <Col style={{ flex: 4, position: 'relative', minHeight: '30rem', paddingLeft: '1rem' }}>
                                        <Row style={{ margin: 0, padding: '0 .75rem' }}>
                                            <Col style={{ flex: 1.5, padding: 0 }}>
                                                <Button
                                                    size="sm"
                                                    type="button"
                                                    variant="success"
                                                    id={savedWorkList.length > 0 ? "online-save-in" : "online-save-out"}
                                                    onClick={this.onSaveClick}
                                                    style={{ paddingLeft: '.725rem', paddingRight: '.725rem', fontSize: '.75rem', fontWeight: '500' }}
                                                >
                                                    SAVE TABLE
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    variant={"secondary"}
                                                    onClick={() => this.setState({ showCancelModal: true })}
                                                    style={{ marginLeft: '.5rem', fontSize: '.75rem', fontWeight: '500' }}
                                                >
                                                    CLEAR TABLE
                                                </Button>
                                                <OverlayTrigger
                                                    placement='bottom'
                                                    overlay={<Tooltip style={{ opacity: .75 }}>Watch a quick tutorial</Tooltip>}
                                                    delay={{ show: 250, hide: 0 }}
                                                >
                                                    <Button
                                                        size="sm"
                                                        variant={"link"}
                                                        onClick={() => this.setState({ showTutorialModal: true })}
                                                    >
                                                        Need help?
                                                </Button>
                                                </OverlayTrigger>
                                                <Modal
                                                    show={showTutorialModal}
                                                    onHide={() => this.setState({ showTutorialModal: false })}
                                                    style={{ width: '78.5%' }}
                                                >
                                                    <ReactPlayer url='https://youtu.be/4DR1zzQ-huI' controls={true} height={"33rem"} width={"50rem"} />
                                                </Modal>
                                            </Col>
                                            <Col style={{ padding: 0, textAlign: 'right' }}>
                                                <ImportSpreadsheet addTableRow={this.addTableRow} />
                                                <Button
                                                    size="sm"
                                                    variant="primary"
                                                    id="online-submit"
                                                    onClick={() => this.sendBonds("view-results")}
                                                    style={{ fontSize: '.75rem', fontWeight: '500', marginLeft: '.5rem' }}
                                                >
                                                    SUBMIT BONDS
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Table id="bond-table" style={{ width: '100%', marginTop: '1rem' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '25%', borderTop: 'none' }}>Issue Date</th>
                                                    <th style={{ width: '25%', borderTop: 'none' }}>Series</th>
                                                    <th style={{ width: '25%', borderTop: 'none' }}>Denomination</th>
                                                    <th style={{ width: '25%', borderTop: 'none' }}>Serial Number</th>
                                                </tr>
                                            </thead>
                                            <tbody></tbody>
                                        </Table>
                                        <Row>
                                            <Col style={{ textAlign: 'right', marginRight: '.75rem' }}>
                                                <Button
                                                    size="sm"
                                                    variant={"outline-dark"}
                                                    onMouseEnter={this.onEnterAddButton}
                                                    onMouseLeave={this.onLeaveAddButton}
                                                    onClick={() => this.addTableRow(null, "EE", 50, 5, 2000)}
                                                    style={{ paddingTop: 0, paddingBottom: '.15rem' }}
                                                >
                                                    <Image src={PlusSign} className={addButtonClass}></Image>
                                                    <span style={{ fontSize: '.75rem', fontWeight: '500', padding: 0, marginLeft: '.1rem' }}>ADD ROW</span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Tab>
                        <Tab eventKey="view-results" title="Results" style={{ padding: '1rem 0' }}>
                            <div style={{ position: 'relative', width: '90%', margin: 'auto' }}>
                                {!hasFullAccess ?
                                    <div style={{ position: 'absolute', top: '-4.5rem', right: '-.5rem' }}>
                                        <span style={{ fontSize: '1.2rem' }}>Want to view your results?</span>
                                        <Button
                                            id="get-full-access"
                                            variant="link"
                                            style={{ marginTop: '-.25rem', fontSize: '1.1rem', fontWeight: 'bold', textDecoration: 'none' }}
                                            onClick={this.getFullAccess}
                                        >
                                            GET FULL ACCESS
                                            <span style={{ position: 'relative', top: '-.15rem', fontSize: '.75rem', fontWeight: 'normal', color: '#000' }}> ($1.00 min)</span>
                                        </Button>
                                        <span style={{ marginTop: '-.25rem', fontSize: '1.1rem' }}>or</span>
                                        <Button
                                            id="get-free-access"
                                            variant="link"
                                            style={{ marginTop: '-.25rem', fontSize: '1.1rem', fontWeight: 'bold', textDecoration: 'none' }}
                                            onClick={this.onFreeCalculatorClick}
                                        >
                                            USE FREE CALCULATOR
                                            {!this.state.isLoggedIn ? <span style={{ position: 'relative', top: '-.15rem', fontSize: '.75rem', fontWeight: 'normal', color: '#000' }}> (requires login)</span> : null}
                                        </Button>
                                    </div>
                                    : null}
                                <BondTable
                                    bonds={this.state.bonds}
                                    filteredBonds={this.state.filteredBonds}
                                    totalDate={this.state.totalDate}
                                    totalPrice={this.state.totalPrice}
                                    totalDenom={this.state.totalDenom}
                                    totalInterest={this.state.totalInterest}
                                    totalValue={this.state.totalValue}
                                    currentMonth={this.state.currentMonth}
                                    currentYear={this.state.currentYear}
                                    windowWidth={this.state.windowWidth}
                                    importableCsv={this.state.importableCsv}
                                    pages={this.state.pages}
                                    currentPage={this.state.currentPageValue}
                                    perPage={this.state.perPage}
                                    hasFullAccess={this.state.hasFullAccess}
                                    setTotals={this.setTotals}
                                    setPrevPage={this.setPrevPage}
                                    setCurrentPage={this.setCurrentPage}
                                    setNextPage={this.setNextPage}
                                    setPerPage={this.setPerPage}
                                    setPages={this.setPages}
                                    setShowMatured={this.setShowMatured}
                                />
                            </div>
                        </Tab>
                    </Tabs >
                </div >
                {
                    this.state.isLoggedIn ?
                        <Modal show={showModal} onHide={() => this.setState({ showModal: false })}>
                            <Modal.Header>
                                <Modal.Title>Please provide a name to save your data.</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Label>Enter name:</Form.Label>
                                <FormControl
                                    id="save-data-name"
                                    placeholder="ex. My EE Savings Bond List"
                                    onFocus={(e) => e.target.placeholder = ""}
                                    onBlur={(e) => e.target.placeholder = "ex. My EE Savings Bond List"}
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            this.setState({ saveDisabled: false })
                                        }
                                        else {
                                            this.setState({ saveDisabled: true })
                                        }
                                    }}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button size="sm" variant="secondary" type="button" onClick={() => this.setState({ showModal: false })}>Close</Button>
                                <Button disabled={saveDisabled} size="sm" variant="success" type="button" onClick={this.saveDataName}>Save</Button>
                            </Modal.Footer>
                        </Modal>
                        :
                        <Modal show={showModal} onHide={() => this.setState({ showModal: false })}>
                            <Modal.Header style={{ margin: 'auto' }}>
                                <Modal.Title style={{ color: 'red' }}>You must be logged in to use this feature!</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                                <Button size="sm" variant="secondary" type="button" onClick={() => this.setState({ showModal: false })}>Close</Button>
                                <Button size="sm" variant="success" type="button" onClick={() => window.location = "/login"}>Login</Button>
                            </Modal.Footer>
                        </Modal>
                }
                < Modal show={showCancelModal} onHide={() => this.setState({ showCancelModal: false })
                }>
                    <Modal.Header style={{ margin: 'auto' }}>
                        <Modal.Title>Are you sure you want to clear your data?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <div style={{ width: '100%' }}>
                            <Button size="sm" variant="danger" type="button" onClick={() => this.resetTable()} style={{ float: 'left' }}>Yes, remove everything</Button>
                            <Button size="sm" variant="secondary" type="button" onClick={() => this.setState({ showCancelModal: false })} style={{ float: 'right' }}>No, not yet</Button>
                        </div>
                    </Modal.Footer>
                </Modal >
                <Helmet>
                    <title>Savings Bond Wizard Replacement - Savings Bond Calculator</title>
                    <meta name='description' content="Quickly assess your savings bond value with our savings bond calculator.  Import from a spreadsheet or build one online to value all your us savings bonds!" />
                    <meta name="keywords" content="savings bond calculator, bond value calculator, savings bond wizard, savings bond wizard replacement, ee savings bond calculator, us savings bond calculator, savings bond value" />
                </Helmet>
            </React.Fragment >
        );
    }
}

export default OnlineSpreadsheet;