import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import TDirectLogo from '../images/treasury-direct.png';
import LogoutLink from '../components/logout-link';
import Footer from '../components/footer';
import setClass from '../setClassName';
import '../styles/home.css';

const cookies = new Cookies();

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalPage: 1,
            windowWidth: window.outerWidth,
            currentPage: window.location.pathname,
            isLoggedIn: false
        };

        this.handleResize = this.handleResize.bind(this)
        this.setLogin = this.setLogin.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (window.outerWidth > 0 && window.outerHeight > 0) {
            this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight })
        }
        else {
            this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
        }

        if (cookies.get('inCookie') === 'in') { this.setState({ isLoggedIn: true }) }


        setTimeout(function () {
            if (localStorage.getItem("viewedModal") === "no") {
                this.setState({ showModal: false });
            }
            else {
                this.setState({ showModal: true });
                localStorage.setItem("viewedModal", "no")
            }
        }.bind(this), 1250);
    }

    handleResize() {
        setTimeout(() => {
            if (window.outerWidth > 0 && window.outerHeight > 0) {
                this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight })
            }
            else {
                this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
            }
        }, 25);
    }

    numClick() {
        window.location.replace("/calculators/savings-bond-calculator")
    }

    setLogin() {
        this.setState({ loggedIn: true })
    }

    render() {
        const { windowWidth, currentPage, showModal } = this.state

        return (
            <React.Fragment>
                <LogoutLink windowWidth={windowWidth} currentPage={currentPage} isLoggedIn={this.state.isLoggedIn} />
                <div className={setClass('home-jumbotron')}>
                    <h1>The Savings Bond Wizard Replacement With Added Features.</h1>
                    <h2 style={{ fontSize: '1rem' }}>Import, manage, and value your bonds with our savings bond calculator!</h2>
                    <div style={{ margin: '4rem 0 0 4rem' }}>
                        <Button
                            size="lg"
                            variant={"success"}
                            style={{ fontSize: '2rem' }}
                            onClick={this.numClick}
                        >
                            CLICK HERE!
                        </Button>
                        <div style={{ marginTop: '1rem', fontSize: '1.05rem', fontWeight: '500' }}>TO VISIT OUR CALCULATOR</div>
                    </div>
                </div>
                <div className={setClass('home-container')}>
                    <Card className={setClass('home-card')}>
                        <Card.Header className={'home-card-header'}>HOW WE DO IT</Card.Header>
                        <Card.Body className={'home-card-body'}>
                            <p><a target="_blank" rel="noopener noreferrer" href="https://www.treasurydirect.gov"><img style={{ width: '7.5rem', marginRight: '.5rem' }} src={TDirectLogo} alt="Treasury Direct" /></a>
                        provides historical <a href="https://www.treasurydirect.gov/indiv/tools/tools_savingsbondvalues_historical.htm">savings bond value files</a> which contain pricing data for all issued I, E/EE Series bonds and/or Savings Notes.
                        We use a database built from these source files to evaluate the price of your bond(s).
                        </p>
                            <ul>
                                <li>100% accurate results</li>
                                <li>View pricing data between May 1992 and May 2020</li>
                            </ul>
                        </Card.Body>
                    </Card>
                    <Card className={setClass('home-card')}>
                        <Card.Header className={'home-card-header'}>WHAT YOU'LL NEED</Card.Header>
                        <Card.Body className={'home-card-body'}>
                            <p style={{ marginBottom: '.5rem' }}>On your bond, you'll need to locate the following items:</p>
                            <p style={{ float: 'left', width: '50%', margin: '.5rem 0', fontWeight: '500' }}>MANDATORY</p>
                            <p style={{ float: 'left', width: '50%', margin: '.5rem 0', fontWeight: '500' }}>OPTIONAL</p>
                            <ListGroup style={{ float: 'left', width: '50%' }}>
                                <ListGroup.Item className={'home-list-item'}>&#8250; Denomination</ListGroup.Item>
                                <ListGroup.Item className={'home-list-item'}>&#8250; Issue Date</ListGroup.Item>
                                <ListGroup.Item className={'home-list-item'}>&#8250; Series</ListGroup.Item>
                            </ListGroup>
                            <ListGroup style={{ float: 'left', width: '50%' }}>
                                <ListGroup.Item className={'home-list-item'} >&#8250; Serial Number</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                    {/* <Card className={setClass('home-card')}>
                        <Card.Header className={'home-card-header'}>FOR ASSISTANCE</Card.Header>
                        <Card.Body className={'home-card-body'}>
                            <p style={{ marginBottom: '.5rem', textAlign: 'left' }}><a href="./instructions/import-wizard-steps">Build a spreadsheet to import</a></p>
                            <p style={{ marginBottom: '.5rem', textAlign: 'left' }}><a href="./instructions/bond-anatomy">Locate mandatory components on your bond</a></p>
                            <p style={{ marginBottom: '.5rem', textAlign: 'left' }}>To view our current<a href="./how-to/savings-bond-redemption-value"> How To </a>articles</p>
                        </Card.Body>
                    </Card> */}
                </div>
                <Modal show={showModal} onHide={() => this.setState({ showModal: false })}>
                    <Modal.Header>
                        <Modal.Title>How many savings bonds do you have?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <input id="1-to-10" onClick={this.numClick} style={{ marginRight: '.5rem' }} type="radio" />
                            <label>Between 1 and 10</label><br />
                            <input id="10-to-50" onClick={this.numClick} style={{ marginRight: '.5rem' }} type="radio" />
                            <label>Between 10 and 50</label><br />
                            <input id="50-plus" onClick={this.numClick} style={{ marginRight: '.5rem' }} type="radio" />
                            <label>More than 50</label><br />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant="secondary" type="button" onClick={() => this.setState({ showModal: false })}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Footer />
                <Helmet>
                    <title>Savings Bond Wizard Replacement - Savings Bond Calculator</title>
                    <meta name='description' content="An alternative to the retired Savings Bond Wizard.  Import or build a spreadsheet of your bonds online to quickly determine the value of your I, E, and EE savings bonds!" />
                    <meta name="keywords" content="savings bond calculator, bond value calculator, savings bond wizard, savings bond wizard replacement, savings bond wizard alternative, bond value calculator, savings bond wizard alternative, savings bond calculator, savings bond redemption value, savings bond worth, savings bond value, u s bond value" />
                </Helmet>
            </React.Fragment >
        )
    }
}

export default Home;