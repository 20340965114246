import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';

// 'http://127.0.0.1:5000'
// 'https://secure.savingsbondsolutions.com'

class MedicalCoding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: null,
            procedures: null,
            diagnoses: null,
            modifiers: null,
            showTable: false,
            showAddForm: false,
            showNewForm: false,
            showAlert: false,
            searchText: '',
            codeValue: '',
            codeDescription: '',
            currentButton: ''
        }

        this.searchEntries = this.setSearchText.bind(this)
        this.addButtonClick = this.addButtonClick.bind(this)
        this.newButtonClick = this.newButtonClick.bind(this)
        this.handleAddSubmit = this.handleAddSubmit.bind(this)
        this.handleNewSubmit = this.handleNewSubmit.bind(this)
        this.cptCodeOnClick = this.cptCodeOnClick.bind(this)
        this.diagnosisCodeOnClick = this.diagnosisCodeOnClick.bind(this)
        this.modifierCodeOnClick = this.modifierCodeOnClick.bind(this)
        this.onDeleteClick = this.onDeleteClick.bind(this)
    }

    componentDidMount() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
        };

        fetch('https://secure.savingsbondsolutions.com/get-doctor-descriptions', requestOptions).then(response => response.json())
            .then(response => {
                console.log(response['entries'])
                this.setState({ entries: response['entries'] })
            });

        fetch('https://secure.savingsbondsolutions.com/get-cpt-codes', requestOptions).then(response => response.json())
            .then(response => {
                this.setState({ procedures: response['procedures'] })
            });

        fetch('https://secure.savingsbondsolutions.com/get-diagnosis-codes', requestOptions).then(response => response.json())
            .then(response => {
                this.setState({ diagnoses: response['diagnosis'] })
            });

        fetch('https://secure.savingsbondsolutions.com/get-modifier-codes', requestOptions).then(response => response.json())
            .then(response => {
                this.setState({ modifiers: response['modifier'] })
            });
    }

    setSearchText(e) {
        const { entries } = this.state
        let inputText = e.target.value

        this.setState({ searchText: inputText, codeValue: '', codeDescription: '', showTable: false, showAddForm: false, showNewForm: false })

        if (inputText.length > 0) {
            entries.filter(item => item.description.toLowerCase().includes(e.target.value.toLowerCase())).map((entry) => (
                this.setState({ showTable: true })
            ))
        }
        else {
            this.setState({ showTable: false, codeValue: '', codeDescription: '' })
        }
    }

    addButtonClick(buttonText) {
        this.setState({ currentButton: buttonText, showAddForm: true, showNewForm: false, showTable: false })
    }

    newButtonClick() {
        this.setState({ showNewForm: true, showAddForm: false, showTable: false })
    }

    cptCodeOnClick(cptCode) {
        const { procedures } = this.state

        try {
            let procedure = procedures.find(element => element[cptCode + "-code"])

            let code = procedure[cptCode + "-code"]
            let description = procedure[cptCode + "-description"]

            this.setState({ codeValue: code + ": ", codeDescription: description })
        }
        catch {
            this.setState({ codeValue: null, codeDescription: "This CPT code has not been added to the database yet." })
        }
    }

    diagnosisCodeOnClick(diagnosisCode) {
        const { diagnoses } = this.state

        try {
            let diagnosis = diagnoses.find(element => element[diagnosisCode + "-code"])

            let code = diagnosis[diagnosisCode + "-code"]
            let description = diagnosis[diagnosisCode + "-description"]

            this.setState({ codeValue: code + ": ", codeDescription: description })
        }
        catch {
            this.setState({ codeValue: null, codeDescription: "This ICD-10 code has not been added to the database yet." })
        }
    }

    modifierCodeOnClick(modifierCode) {
        const { modifiers } = this.state

        try {
            let modifier = modifiers.find(element => element[modifierCode + "-code"])

            let code = modifier[modifierCode + "-code"]
            let description = modifier[modifierCode + "-description"]

            this.setState({ codeValue: code + ": ", codeDescription: description })
        }
        catch {
            this.setState({ codeValue: null, codeDescription: "This modifier has not been added to the database yet." })
        }


    }

    handleAddSubmit(e) {
        e.preventDefault()
        const { currentButton } = this.state

        let newEntry = {
            "code": e.target.code.value,
            "description": e.target.description.value
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ newEntry })
        };

        if (currentButton === "CPT" && newEntry['code'].length > 0) {
            fetch('https://secure.savingsbondsolutions.com/add-cpt-code', requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response["success"]) {
                        this.setState({ showAlert: true });

                        setTimeout(() => {
                            this.setState({ showAlert: false });
                            window.location.href = window.location.pathname
                        }, 1000)
                    }
                });
        }

        else if (currentButton === "ICD-10" && newEntry['code'].length > 0) {
            fetch('https://secure.savingsbondsolutions.com/add-diagnosis-code', requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response["success"]) {
                        this.setState({ showAlert: true });

                        setTimeout(() => {
                            this.setState({ showAlert: false });
                            window.location.href = window.location.pathname
                        }, 1000)
                    }
                });
        }

        else if (currentButton === "MODIFIER" && newEntry['code'].length > 0) {
            fetch('https://secure.savingsbondsolutions.com/add-modifier-code', requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response["success"]) {
                        this.setState({ showAlert: true });

                        setTimeout(() => {
                            this.setState({ showAlert: false });
                            window.location.href = window.location.pathname
                        }, 1000)
                    }
                });
        }
    }

    handleNewSubmit(e) {
        e.preventDefault()

        let cptCodes = [e.target.cptOne.value, e.target.cptTwo.value, e.target.cptThree.value, e.target.cptFour.value]
        cptCodes = cptCodes.filter(n => n)
        cptCodes = cptCodes.join(",")

        let diagnosisCodes = [e.target.diagnosisOne.value, e.target.diagnosisTwo.value, e.target.diagnosisThree.value, e.target.diagnosisFour.value]
        diagnosisCodes = diagnosisCodes.filter(n => n)
        diagnosisCodes = diagnosisCodes.join(",")

        let modifierCodes = [e.target.modifierOne.value, e.target.modifierTwo.value, e.target.modifierThree.value, e.target.modifierFour.value]
        modifierCodes = modifierCodes.filter(n => n)
        modifierCodes = modifierCodes.join(",")

        let doctorDescription = e.target.doctorDescription.value

        let entry = {
            'cptCodes': cptCodes,
            'diagnosisCodes': diagnosisCodes,
            'modifierCodes': modifierCodes,
            'doctorDescription': doctorDescription
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ entry })
        };


        if (doctorDescription.length > 0) {
            fetch('https://secure.savingsbondsolutions.com/add-doctor-description', requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response["success"]) {
                        this.setState({ showAlert: true });

                        setTimeout(() => {
                            this.setState({ showAlert: false });
                            window.location.href = window.location.pathname
                        }, 1000)
                    }
                });
        }
    }

    onDeleteClick(entryToRemove) {
        const { entries } = this.state

        this.setState({
            entries: entries.filter(function (entry) {
                return entry !== entryToRemove
            })
        });

        let removeEntry = {
            'cpt': entryToRemove['cpt'].join(","),
            'icd': entryToRemove['icd'].join(","),
            'mod': entryToRemove['mod'].join(","),
            'description': entryToRemove['description']
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ removeEntry })
        };

        fetch('https://secure.savingsbondsolutions.com/remove-doctor-description', requestOptions)
            .then(response => response.json())
            .then(response => {
                console.log(response)
            });
    }

    render() {
        const { entries, searchText, showTable, codeValue, codeDescription, currentButton, showAddForm, showNewForm, showAlert } = this.state

        return (
            <React.Fragment>
                <Row style={{ width: '75%', margin: 'auto', position: 'relative' }}>
                    <Col style={{ textAlign: 'right', position: 'absolute', top: '1.25rem', zIndex: 9999 }}>
                        <Button variant='primary' style={{ marginLeft: '1rem', fontSize: '.75rem', fontWeight: 500 }} onClick={() => this.addButtonClick("CPT")}>ADD CPT CODE</Button>
                        <Button variant='primary' style={{ marginLeft: '1rem', fontSize: '.75rem', fontWeight: 500 }} onClick={() => this.addButtonClick("ICD-10")}>ADD ICD-10 CODE</Button>
                        <Button variant='primary' style={{ marginLeft: '1rem', fontSize: '.75rem', fontWeight: 500 }} onClick={() => this.addButtonClick("MODIFIER")}>ADD MODIFER CODE</Button>
                        <Button variant='success' style={{ marginLeft: '1rem', fontSize: '.75rem', fontWeight: 500 }} onClick={() => this.newButtonClick()}>NEW DOCTOR ENTRY</Button>
                    </Col>
                </Row>
                <Row style={{ width: '75%', margin: '2rem auto' }}>
                    <Col>
                        <Form onSubmit={e => { e.preventDefault(); }}>
                            <Form.Label className={'form-label'}>SEARCH</Form.Label>
                            <Form.Control
                                name="mc-search-text"
                                type="text"
                                className={'bond-input'}
                                style={{ borderRadius: 0 }}
                                onChange={(e) => { this.setSearchText(e) }}
                            />
                        </Form>
                    </Col>
                </Row>
                {
                    showTable ?
                        <Row style={{ width: '75%', margin: '1rem auto', minHeight: '1.5rem' }}>
                            <Col>
                                <span><b>{codeValue}</b>{codeDescription}</span>
                            </Col>
                        </Row>
                        : null
                }
                {
                    showTable && entries ?
                        <Row style={{ width: '75%', margin: 'auto', display: 'block' }}>
                            <Col>
                                <Table style={{ marginTop: '2rem' }}>
                                    <thead>
                                        <tr style={{ textAlign: 'center', border: '1px solid #ced4da' }}>
                                            <th style={{ borderTop: 'none' }}>CPT Code</th>
                                            <th style={{ borderTop: 'none' }}>ICD-10 Code</th>
                                            <th style={{ borderTop: 'none' }}>Modifier</th>
                                            <th style={{ borderTop: 'none' }}>Doctor Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entries.filter(item => item.description.toLowerCase().includes(searchText.toLowerCase())).map((entry, i) => (
                                            <tr key={i}>
                                                <td style={{ borderTop: 'none', paddingBottom: 0 }}>
                                                    {entry.cpt.map((cpt) => (
                                                        <Button key={cpt} variant="link" onClick={() => this.cptCodeOnClick(cpt)} style={{ padding: 0, marginLeft: '.5rem' }}>
                                                            {cpt}
                                                        </Button>
                                                    ))}
                                                </td>
                                                <td style={{ borderTop: 'none', paddingBottom: 0 }}>
                                                    {entry.icd.map((icd) => (
                                                        <Button key={icd} variant="link" onClick={() => this.diagnosisCodeOnClick(icd)} style={{ padding: 0, marginLeft: '.5rem' }}>
                                                            {icd}
                                                        </Button>
                                                    ))}
                                                </td>
                                                <td style={{ borderTop: 'none', paddingBottom: 0 }}>
                                                    {entry.mod.map((mod) => (
                                                        <Button key={mod} variant="link" onClick={() => this.modifierCodeOnClick(mod)} style={{ padding: 0, marginLeft: '.5rem' }}>
                                                            {mod}
                                                        </Button>
                                                    ))}
                                                </td>
                                                <td style={{ borderTop: 'none', paddingBottom: 0 }}>{entry.description}</td>
                                                <td style={{ borderTop: 'none', paddingBottom: 0, width: '10px', float: 'right' }}>
                                                    <Button variant="link" style={{ padding: 0, color: 'black', textDecoration: 'none' }} onClick={() => this.onDeleteClick(entry)}>x</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        : null
                }
                <Row style={{ width: '75%', margin: 'auto', position: 'relative' }}>
                    <Col>
                        <Alert variant="success" show={showAlert} style={{ position: 'absolute', top: '-1rem', right: '1rem' }}>Successfully added to database</Alert>
                    </Col>
                </Row>
                {showAddForm ?
                    <Row style={{ width: '75%', margin: 'auto' }}>
                        <Col>
                            <h1 style={{ fontSize: '1.75rem', textTransform: 'uppercase' }}>ADD {currentButton} CODE</h1>
                            <Form style={{ marginTop: '1rem' }} onSubmit={this.handleAddSubmit}>
                                <Row>
                                    <Col style={{ flex: .5 }}>
                                        <Form.Control
                                            name="code"
                                            type="text"
                                            placeholder={"Enter " + currentButton + " code"}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            name="description"
                                            as="textarea"
                                            placeholder={"Enter " + currentButton + " code description"}
                                            style={{ height: '100px' }}
                                        />
                                    </Col>
                                </Row>
                                <Button variant="dark" type="submit" style={{ float: 'right', marginTop: '.5rem' }}>SUBMIT</Button>
                            </Form>
                        </Col>
                    </Row>
                    : null}
                {showNewForm ?
                    <Row style={{ width: '75%', margin: 'auto' }}>
                        <Col>
                            <h1 style={{ fontSize: '1.75rem', textTransform: 'uppercase' }}>NEW DOCTOR ENTRY</h1>
                            <Form style={{ marginTop: '1rem' }} onSubmit={this.handleNewSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Label className={'form-label'}>Enter CPT code(s)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <FormControl name="cptOne" />
                                            <InputGroup.Text style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>|</InputGroup.Text>
                                            <FormControl name="cptTwo" />
                                            <InputGroup.Text style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>|</InputGroup.Text>
                                            <FormControl name="cptThree" />
                                            <InputGroup.Text style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>|</InputGroup.Text>
                                            <FormControl name="cptFour" />
                                        </InputGroup>
                                        <Form.Label className={'form-label'}>Enter ICD-10 code(s)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <FormControl name="diagnosisOne" />
                                            <InputGroup.Text style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>|</InputGroup.Text>
                                            <FormControl name="diagnosisTwo" />
                                            <InputGroup.Text style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>|</InputGroup.Text>
                                            <FormControl name="diagnosisThree" />
                                            <InputGroup.Text style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>|</InputGroup.Text>
                                            <FormControl name="diagnosisFour" />
                                        </InputGroup>
                                        <Form.Label className={'form-label'}>Enter MODIFIER code(s)</Form.Label>
                                        <InputGroup>
                                            <FormControl name="modifierOne" />
                                            <InputGroup.Text style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>|</InputGroup.Text>
                                            <FormControl name="modifierTwo" />
                                            <InputGroup.Text style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>|</InputGroup.Text>
                                            <FormControl name="modifierThree" />
                                            <InputGroup.Text style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}>|</InputGroup.Text>
                                            <FormControl name="modifierFour" />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            name="doctorDescription"
                                            as="textarea"
                                            placeholder={"Enter doctor description here..."}
                                            style={{ marginTop: '2rem', height: '13rem' }}
                                        />
                                    </Col>
                                </Row>
                                <Button variant="dark" type="submit" style={{ float: 'right', marginTop: '.5rem' }}>SUBMIT</Button>
                            </Form>
                        </Col>
                    </Row>
                    : null}
            </React.Fragment>
        )
    }
}

export default MedicalCoding;
