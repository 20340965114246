import React, { Component } from 'react';
import LogoutLink from '../logout-link';
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import BondTable from "./bond-table";
import BondFlip from "./bond-flip";
import setClass from '../../setClassName';
import "../../styles/pricing.css";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class IndividualBonds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bonds: [],
            filteredBonds: [],
            totalPrice: 0,
            totalInterest: 0,
            totalValue: 0,
            totalDenom: 0,
            totalDate: this.formatDate(this.getCurrentMonth() + '/' + this.getCurrentYear()),
            updateDate: this.getCurrentMonth() + '/' + this.getCurrentYear(),
            currentMonth: this.getCurrentMonth(),
            currentYear: this.getCurrentYear(),
            denominationOptions: [50, 75, 100, 200, 500, 1000, 5000, 10000],
            showTooltip: { visibility: 'visible' },
            windowWidth: window.outerWidth,
            windowHeight: window.outerHeight,
            currentPage: window.location.pathname,
            hiddenTable: true,
            errorMessage: null,
            pages: [],
            perPage: 5,
            currentPageValue: 0,
            valueMonth: this.getCurrentMonth(),
            valueYear: this.getCurrentYear(),
            importableSpreadsheet: true,
            hasFullAccess: true,
            isLoggedIn: false
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.onUpdateEnter = this.onUpdateEnter.bind(this)
        this.onUpdateClick = this.onUpdateClick.bind(this)
        this.setTotals = this.setTotals.bind(this)
        this.setPrevPage = this.setPrevPage.bind(this)
        this.setCurrentPage = this.setCurrentPage.bind(this)
        this.setNextPage = this.setNextPage.bind(this)
        this.setPerPage = this.setPerPage.bind(this)
        this.setPages = this.setPages.bind(this)
        this.setShowMatured = this.setShowMatured.bind(this)
        this.setCardTotals = this.setCardTotals.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        // cookies.remove('inCookie', { path: '/' });

        if (window.outerWidth > 0 && window.outerHeight > 0) {
            this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight })
        }
        else {
            this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
        }

        if (cookies.get('inCookie') === 'in') { this.setState({ isLoggedIn: true }) }
    }

    handleResize() {
        setTimeout(() => {
            if (window.outerWidth > 0 && window.outerHeight > 0) {
                this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight })
            }
            else {
                this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
            }
        }, 25);
    }

    setCardTotals(bonds) {
        let denTotal = [];
        let cosTotal = [];
        let intTotal = [];
        let valTotal = [];

        for (let i = 0; i < bonds.length; i++) {
            denTotal.push(bonds[i]['denomination'])
            cosTotal.push(bonds[i]['issuePrice'])
            intTotal.push(bonds[i]['interest'])
            valTotal.push(bonds[i]['value'])
        }

        this.setState({
            totalDenom: denTotal.reduce(function (a, v) { return a + v; }, 0),
            totalPrice: cosTotal.reduce(function (a, v) { return a + v; }, 0),
            totalInterest: intTotal.reduce(function (a, v) { return a + v; }, 0),
            totalValue: valTotal.reduce(function (a, v) { return a + v; }, 0)
        });
    }

    setShowMatured(e) {
        const { bonds } = this.state

        if (e.target.checked) {
            let filteredBonds = bonds.filter(bond => bond['accrualDate'] === 'Matured')

            this.setState({ filteredBonds: filteredBonds })
            this.setCardTotals(filteredBonds)
            this.setPages(filteredBonds, this.state.perPage)

        }
        else {
            this.setState({ filteredBonds: bonds })
            this.setCardTotals(bonds)
            this.setPages(bonds, this.state.perPage)
        }
    }

    setTotals(denomination, price, interest, value) {
        this.setState({
            totalDenom: this.state.totalDenom - denomination,
            totalPrice: this.state.totalPrice - price,
            totalInterest: this.state.totalInterest - interest,
            totalValue: this.state.totalValue - value
        })
    }

    onValueMonthChange(e) {
        this.setState({ valueMonth: e.target.value })
    }

    onValueYearChange(e) {
        this.setState({ valueYear: e.target.value })
    }

    setPages(bonds, perPage) {
        let pages = []
        let pageCount = Math.ceil(bonds.length / perPage)

        let i = 0
        while (i < pageCount) {
            pages.push(i)

            i++
        }

        this.setState({ pages: pages })
    }


    setCurrentPage(i) {
        this.setState({ currentPageValue: i })
    }

    setPrevPage() {
        let showCount = (this.state.currentPageValue - 1) * this.state.perPage
        if (showCount >= 0) {
            this.setState({ currentPageValue: this.state.currentPageValue - 1 })
        }
    }

    setNextPage() {
        let showCount = (this.state.currentPageValue + 1) * this.state.perPage
        if (showCount < this.state.bonds.length) {
            this.setState({ currentPageValue: this.state.currentPageValue + 1 })
        }
    }

    setPerPage(e) {
        let rowCount = parseInt(e.target.value)

        if (rowCount > 0) {
            this.setPages(this.state.bonds, rowCount);

            this.setState({ currentPageValue: 0, perPage: rowCount })
        }
    }

    getCurrentMonth() {
        let d = new Date();
        return d.getMonth() + 1
    }

    getCurrentYear() {
        let d = new Date();

        return d.getFullYear()
    }

    formatDate(value_date) {
        const months = { "01": "Jan.", "02": "Feb.", "03": "Mar.", "04": "April", "05": "May", "06": "June", "07": "July", "08": "Aug.", "09": "Sept.", "10": "Oct.", "11": "Nov.", "12": "Dec." }

        let month = value_date.split('/')[0]

        if (month.length === 1) {
            month = '0' + month
        }

        let year = value_date.split('/')[1]

        let month_string = months[month] + ' ' + year

        return month_string
    }

    handleError(series) {
        if (series === 'I') {
            this.setState({ errorMessage: "Series I Bonds have been issued from September 1998 to the present. Please adjust your issue date and re-submit." })
        }
        else if (series === 'EE') {
            this.setState({ errorMessage: "Series EE Bonds have been issued from January 1980 to the present. Please adjust your issue date and re-submit." })
        }
        else if (series === 'E') {
            this.setState({ errorMessage: "Series E Bonds were issued between May 1941 and June 1980. Please adjust your issue date and re-submit." })
        }
        else if (series === 'S') {
            this.setState({ errorMessage: "Savings Notes were issued between May 1967 and October 1970. Please adjust your issue date and re-submit." })
        }
    }

    onUpdateEnter(e) {
        e.preventDefault();

        setTimeout(() => {
            this.setState({ showTooltip: { visibility: 'hidden' } })
        }, 3500)
    }

    onUpdateClick() {
        const bonds = this.state.bonds
        this.setState({ updateDate: document.getElementsByName('valueMonth')[0].value + '/' + document.getElementsByName('valueYear')[0].value })

        let submitBonds = []

        for (let i = 0; i < bonds.length; i++) {
            let bond = {
                'valueMonth': parseInt(document.getElementsByName('valueMonth')[0].value),
                'valueYear': parseInt(document.getElementsByName('valueYear')[0].value),
                'serialNumber': bonds[i]['serialNumber'],
                'series': bonds[i]['series'],
                'denomination': bonds[i]['denomination'],
                'issueMonth': parseInt(bonds[i]['issueDate'].split('/')[0]),
                'issueYear': parseInt(bonds[i]['issueDate'].split('/')[1])
            }

            submitBonds.push(bond)
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ submitBonds })
        };

        //http://localhost:5000/update
        //https://secure.savingsbondsolutions.com/update

        fetch('https://secure.savingsbondsolutions.com/update', requestOptions)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    bonds: response[0],
                    filteredBonds: response[0],
                    totalDate: this.formatDate(response[1]['totalDate']),
                    totalDenom: response[1]['totalDenoms'],
                    totalPrice: response[1]['totalPrices'],
                    totalInterest: response[1]['totalInterests'],
                    totalValue: response[1]['totalValues']
                })
            })
    }

    onSubmit(e) {
        e.preventDefault();
        const bondState = this.state.bonds

        let submitBonds = []

        let bond = {
            'valueMonth': parseInt(e.target.valueMonth.value),
            'valueYear': parseInt(e.target.valueYear.value),
            'serialNumber': e.target.serialnumber.value,
            'series': e.target.series.value,
            'denomination': parseInt(e.target.denomination.value),
            'issueMonth': parseInt(e.target.issueMonth.value),
            'issueYear': parseInt(e.target.issueYear.value)
        }

        submitBonds.push(bond)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ submitBonds })
        };

        //http://localhost:5000/update
        //https://secure.savingsbondsolutions.com/update

        fetch('https://secure.savingsbondsolutions.com/update', requestOptions)
            .then(response => response.json())
            .then(response => {
                if (response[2].length === 0) {

                    const { bonds, totalDenom, totalPrice, totalInterest, totalValue } = this.state
                    let nextBond = response[0]

                    if (bonds.length > 0) {
                        for (let i = 0; i < bonds.length; i++) {
                            nextBond.push(bonds[i])
                        }
                    }

                    this.setState({
                        bonds: nextBond,
                        filteredBonds: nextBond,
                        totalDate: this.formatDate(response[1]['totalDate']),
                        totalDenom: totalDenom + response[1]['totalDenoms'],
                        totalPrice: totalPrice + response[1]['totalPrices'],
                        totalInterest: totalInterest + response[1]['totalInterests'],
                        totalValue: totalValue + response[1]['totalValues'],
                        errorMessage: null
                    })

                    this.setPages(this.state.bonds, this.state.perPage)

                    if (bondState.length > 0 && this.state.updateDate !== this.state.valueMonth + '/' + this.state.valueYear) {
                        this.onUpdateClick()
                    }

                    document.getElementsByName("serialnumber")[0].value = ""

                    document.getElementById('filter-matured').parentNode.style.display = "none"
                    document.getElementsByClassName('export-dropdown')[0].style.display = "none"
                }
                else {
                    this.handleError(response[2][0]['series'])
                }
            })
    }

    convertValues(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    onSeriesChange() {
        let series = document.getElementsByName('series')[0].value

        if (series === 'S') {
            this.setState({ denominationOptions: [25, 50, 75, 100] })
        }
        else if (series === 'E') {
            this.setState({ denominationOptions: [10, 25, 50, 75, 100, 200, 500, 1000, 5000, 10000] })
        }
        else {
            this.setState({ denominationOptions: [25, 50, 75, 100, 200, 500, 1000, 5000, 10000] })
        }
    }

    render() {
        const { windowHeight, windowWidth, currentPage } = this.state

        return (
            <React.Fragment>
                <LogoutLink windowWidth={windowWidth} currentPage={currentPage} isLoggedIn={this.state.isLoggedIn} />
                <div className={setClass('bond-container')} style={!isMobile ? { position: 'relative', minWidth: (windowWidth * .7) } : null}>
                    {windowWidth > 500 ?
                        <React.Fragment>
                            <Form onSubmit={this.onSubmit} style={{ marginTop: '1rem' }}>
                                <Form.Row>
                                    <Col>
                                        <Form.Label className={setClass('form-label')}>{windowHeight <= 320 ? 'VAL. AS OF' : 'VALUE AS OF'}</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                required
                                                name="valueMonth"
                                                type="number"
                                                placeholder="Mo"
                                                min="1"
                                                max="12"
                                                defaultValue={this.state.currentMonth}
                                                className={setClass("form-month")}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text className={setClass('form-seperator')}>/</InputGroup.Text>
                                            </InputGroup.Append>
                                            <Form.Control
                                                required
                                                name="valueYear"
                                                type="number"
                                                placeholder="Year"
                                                maxLength="4"
                                                min="1992"
                                                max={this.state.currentYear}
                                                className={setClass("form-year")}
                                                defaultValue={this.state.currentYear}
                                            />
                                        </InputGroup>
                                        <OverlayTrigger placement='right' overlay={
                                            <Tooltip style={this.state.showTooltip} >
                                                Revalue all bonds by this date.
                                </Tooltip>}>
                                            <Button
                                                size='sm'
                                                type="button"
                                                variant='outline-success'
                                                className={setClass("form-button")}
                                                onMouseEnter={this.onUpdateEnter}
                                                onClick={this.onUpdateClick}>
                                                UPDATE LIST
                                        </Button>
                                        </OverlayTrigger>
                                    </Col>
                                    <Col>
                                        <Form.Label className={setClass('form-label')}>SERIES</Form.Label>
                                        <Form.Control
                                            className={setClass('bond-input')}
                                            onChange={() => this.onSeriesChange()}
                                            name="series"
                                            as="select"
                                            custom
                                        >
                                            <option value="EE">EE Bond</option>
                                            <option value="I">I Bond</option>
                                            <option value="E">E Bond</option>
                                            <option value="S">Savings Note</option>
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label className={setClass('form-label')}>DENOMINATION</Form.Label>
                                        <Form.Control className={setClass('bond-input')} name="denomination" as="select" custom>
                                            {this.state.denominationOptions.map((opt) => (
                                                <option key={opt} value={opt}>${this.convertValues(opt)}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label className={setClass('form-label')}>SERIAL NUMBER</Form.Label>
                                        <Form.Control
                                            name="serialnumber"
                                            type="text"
                                            placeholder="Optional"
                                            className={setClass('bond-input')}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label className={setClass('form-label')}>ISSUE DATE</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                required
                                                name="issueMonth"
                                                type="number"
                                                placeholder="Mo"
                                                maxLength="2"
                                                defaultValue="5"
                                                min="1"
                                                max="12"
                                                className={this.state.errorMessage ? setClass('form-month') + ' highlight' : setClass('form-month')}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text className={setClass('form-seperator')}>/</InputGroup.Text>
                                            </InputGroup.Append>
                                            <Form.Control
                                                required
                                                name="issueYear"
                                                type="number"
                                                placeholder="Year"
                                                maxLength="4"
                                                defaultValue="2000"
                                                max={this.state.currentYear}
                                                className={this.state.errorMessage ? setClass('form-year') + ' highlight' : setClass('form-year')}
                                            />
                                        </InputGroup>
                                        <Button id="submit-calculator" type="submit" size='sm' variant="primary" className={setClass("form-button")}>ADD TO LIST</Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                            {this.state.errorMessage ? <Alert className='bond-alerts' variant='danger'>{this.state.errorMessage}</Alert> : null}
                            <BondTable
                                bonds={this.state.bonds}
                                filteredBonds={this.state.filteredBonds}
                                totalDate={this.state.totalDate}
                                totalPrice={this.state.totalPrice}
                                totalDenom={this.state.totalDenom}
                                totalInterest={this.state.totalInterest}
                                totalValue={this.state.totalValue}
                                currentMonth={this.state.currentMonth}
                                currentYear={this.state.currentYear}
                                hiddenTable={this.state.hiddenTable}
                                hasFullAccess={this.state.hasFullAccess}
                                windowWidth={windowWidth}
                                windowHeight={this.state.windowHeight}
                                importableSpreadsheet={this.state.importableSpreadsheet}
                                pages={this.state.pages}
                                currentPage={this.state.currentPageValue}
                                perPage={this.state.perPage}
                                setTotals={this.setTotals}
                                setPrevPage={this.setPrevPage}
                                setCurrentPage={this.setCurrentPage}
                                setNextPage={this.setNextPage}
                                setPerPage={this.setPerPage}
                                setPages={this.setPages}
                                setShowMatured={this.setShowMatured}
                            />
                        </React.Fragment> : <BondFlip />}
                    <Helmet>
                        <title>Savings Bond Calculator - Savings Bond Solutions</title>
                        <meta name='description' content="Individually price and value any set of E, EE, I Bonds and/or Savings Notes then export the aggregated results!" />
                        <meta name="keywords" content="savings bond wizard, savings bond wizard replacement, savings bond wizard alternative, savings bond calculator, savings bond redemption value, savings bond worth, savings bond value" />
                    </Helmet>
                </div>
            </React.Fragment>
        );
    }
}

export default IndividualBonds;