import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import ReactExport from "react-data-export";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import BondCards from "./bond-cards";
import setClass from '../../setClassName';
import "../../styles/pricing.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class BondTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportToCsv: [],
            exportToXlsx: [],
            exportToImportCsv: [],
            exportToImportXlsx: []
        }

        this.handleClickX = this.handleClickX.bind(this)
        this.exportToSpreadsheet = this.exportToSpreadsheet.bind(this)
    }

    handleClickX(i) {
        const { currentPage, perPage } = this.props
        const newBonds = this.props.bonds

        let x = (this.props.currentPage * this.props.perPage) + i

        this.props.setTotals(newBonds[x]['denomination'], newBonds[x]['issuePrice'], newBonds[x]['interest'], newBonds[x]['value'])

        newBonds.splice(x, 1)

        this.props.setPages(newBonds, this.props.perPage)

        if ((newBonds.length > 0) && (currentPage === Math.floor(newBonds.length / perPage))) {
            if ((newBonds.length % perPage) === 0) {
                this.props.setCurrentPage(currentPage - 1)
            }
        }
    }

    exportToSpreadsheet() {
        let bonds

        let checkbox = document.getElementById("filter-matured")

        if (checkbox.checked) {
            bonds = this.props.filteredBonds
        }
        else {
            bonds = this.props.bonds
        }

        let bondExportsCsv = []
        let bondExportsXlsx = []
        let bondImportsCsv = []
        let bondImportsXlsx = []

        bondExportsCsv.push(['Lookup Date', 'Serial Number', 'Series', 'Bond Amount', 'Issue Date', 'Next Accrual', 'Final Maturity', 'Issue Price', 'Interest', 'Bond Value'])
        bondImportsCsv.push(['Serial Number [optional]', 'Series [I, E, EE, S]', 'Denomination [10 to 10000]', 'Issue Month	Issue Year'])

        for (let i = 0; i < bonds.length; i++) {

            let bondExportCsv = [
                bonds[i]['valueDate'],
                bonds[i]['serialNumber'],
                bonds[i]['series'],
                '$' + bonds[i]['denomination'],
                bonds[i]['issueDate'],
                bonds[i]['accrualDate'],
                bonds[i]['maturityDate'],
                '$' + parseFloat(bonds[i]['issuePrice']).toFixed(2),
                '$' + parseFloat(bonds[i]['interest']).toFixed(2),
                '$' + parseFloat(bonds[i]['value']).toFixed(2)
            ]

            let bondExportXlsx = {
                'valueDate': bonds[i]['valueDate'],
                'serialNumber': bonds[i]['serialNumber'],
                'series': bonds[i]['series'],
                'denomination': bonds[i]['denomination'],
                'issueDate': bonds[i]['issueDate'],
                'accrualDate': bonds[i]['accrualDate'],
                'maturityDate': bonds[i]['maturityDate'],
                'issuePrice': bonds[i]['issuePrice'],
                'interest': bonds[i]['interest'],
                'value': bonds[i]['value']
            }

            let bondImportCsv = [
                bonds[i]['serialNumber'],
                bonds[i]['series'],
                bonds[i]['denomination'],
                bonds[i]['issueDate'].split('/')[0],
                bonds[i]['issueDate'].split('/')[1]
            ]

            let bondImportXlsx = {
                'serialNumber': bonds[i]['serialNumber'],
                'series': bonds[i]['series'],
                'denomination': bonds[i]['denomination'],
                'issueDate': bonds[i]['issueDate']
            }

            bondExportsCsv.push(bondExportCsv);
            bondExportsXlsx.push(bondExportXlsx);
            bondImportsCsv.push(bondImportCsv);
            bondImportsXlsx.push(bondImportXlsx);
        }

        bondExportsCsv.push([
            "",
            "",
            "",
            '$' + this.props.totalDenom,
            "",
            "",
            "",
            '$' + parseFloat(this.props.totalPrice).toFixed(2),
            '$' + parseFloat(this.props.totalInterest).toFixed(2),
            '$' + parseFloat(this.props.totalValue).toFixed(2)
        ])

        bondExportsXlsx.push(
            {
                'valueDate': '',
                'serialNumber': '',
                'series': '',
                'denomination': '$' + this.props.totalDenom,
                'issueDate': '',
                'accrualDate': '',
                'maturityDate': '',
                'issuePrice': '$' + parseFloat(this.props.totalPrice).toFixed(2),
                'interest': '$' + parseFloat(this.props.totalInterest).toFixed(2),
                'value': '$' + parseFloat(this.props.totalValue).toFixed(2)
            }
        )

        this.setState({
            exportToCsv: bondExportsCsv,
            exportToXlsx: bondExportsXlsx,
            exportToImportCsv: bondImportsCsv,
            exportToImportXlsx: bondImportsXlsx,
        })
    }

    convertValues(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    render() {
        const { exportToCsv, exportToXlsx, exportToImportCsv, exportToImportXlsx } = this.state
        const { bonds, filteredBonds, currentPage, perPage, windowHeight, windowWidth, hasFullAccess } = this.props

        return (
            <React.Fragment>
                <BondCards
                    totalDate={this.props.totalDate}
                    totalPrice={this.props.totalPrice}
                    totalDenom={this.props.totalDenom}
                    totalInterest={this.props.totalInterest}
                    totalValue={this.props.totalValue}
                    windowWidth={this.props.windowWidth}
                    hasFullAccess={this.props.hasFullAccess}
                />
                {this.props.bonds.length > 0 ?
                    <div>
                        <div style={{ float: 'left' }}>
                            <input
                                type="checkbox"
                                id="filter-matured"
                                name="filter-matured"
                                onClick={this.props.setShowMatured}
                                style={{ position: 'relative', top: '.25rem' }}
                            />
                            <label style={{ position: 'relative', top: '.2rem', left: '.35rem', fontSize: '.875rem' }}>Show matured bonds only</label>
                        </div>
                        <Dropdown onClick={this.exportToSpreadsheet} className={setClass('export-dropdown')} style={{ float: 'right', marginBottom: '.5rem' }}>
                            <Dropdown.Toggle variant='secondary'>EXPORT</Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: '5rem', fontSize: '1rem' }}>
                                <CSVLink filename={'sbs_csv_export_from_' + this.props.currentMonth + '_' + this.props.currentYear} data={exportToCsv}>
                                    <Button variant="outline-light" style={{ width: '100%', textAlign: 'left', color: '#000' }} >CSV (.csv)</Button>
                                </CSVLink>
                                <ExcelFile filename={'sbs_xlsx_export_from_' + this.props.currentMonth + '_' + this.props.currentYear} element={<Button variant="outline-light" style={{ width: '100%', textAlign: 'left', color: '#000' }}>Excel (.xlsx)</Button>}>
                                    <ExcelSheet data={exportToXlsx} name="ExportXlsx">
                                        <ExcelColumn label="Value Date" value="valueDate" />
                                        <ExcelColumn label="Serial Number" value="serialNumber" />
                                        <ExcelColumn label="Series" value="series" />
                                        <ExcelColumn label="Denomination" value="denomination" />
                                        <ExcelColumn label="Issue Date" value="issueDate" />
                                        <ExcelColumn label="Accrual Date" value="accrualDate" />
                                        <ExcelColumn label="Maturity Date" value="maturityDate" />
                                        <ExcelColumn label="Issue Price" value="issuePrice" />
                                        <ExcelColumn label="Interest" value="interest" />
                                        <ExcelColumn label="Value" value="value" />
                                    </ExcelSheet>
                                </ExcelFile>
                                {this.props.importableSpreadsheet ?
                                    <React.Fragment>
                                        <CSVLink filename={'sbs_csv_import_file'} data={exportToImportCsv}>
                                            <Button variant="outline-light" style={{ width: '100%', textAlign: 'left', color: '#000', whiteSpace: 'nowrap', paddingRight: '1rem' }}>Importable (.csv)</Button>
                                        </CSVLink>
                                        <ExcelFile filename={'sbs_xlsx_import_file'} element={<Button variant="outline-light" style={{ width: '100%', textAlign: 'left', color: '#000', whiteSpace: 'nowrap', paddingRight: '1rem' }}>Importable (.xlsx)</Button>}>
                                            <ExcelSheet data={exportToImportXlsx} name="ImportXlsx">
                                                <ExcelColumn label="Serial Number" value="serialNumber" />
                                                <ExcelColumn label="Series" value="series" />
                                                <ExcelColumn label="Denomination" value="denomination" />
                                                <ExcelColumn label="Issue Date" value="issueDate" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </React.Fragment>
                                    : null}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    : null
                }
                <Table className={setClass('bond-table')} size={windowWidth < 1024 ? 'sm' : 'lg'} striped hover hidden={bonds.length > 0 ? false : true} style={this.props.bonds.length < this.props.perPage ? { marginBottom: '1rem' } : null}>
                    <thead className={setClass('bond-table-head')}>
                        <tr>
                            <th>Serial #</th>
                            <th>Series</th>
                            <th>Face Value</th>
                            <th>Issue Date</th>
                            <th>Next Accrual</th>
                            <th>Final Maturity</th>
                            <th>Issue Price</th>
                            <th>Interest</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredBonds
                            .slice(currentPage * perPage, (currentPage + 1) * perPage)
                            .map((bond, i) => (
                                <tr key={i} className={setClass('bond-table-row')}>
                                    <td>{bond['serialNumber']}</td>
                                    <td>{bond['series']}</td>
                                    <td>${this.convertValues(bond['denomination'])}</td>
                                    <td>{bond['issueDate']}</td>
                                    <td>{bond['accrualDate']}</td>
                                    <td>{bond['maturityDate']}</td>
                                    <td>${this.convertValues(parseFloat(bond['issuePrice']).toFixed(2))}</td>
                                    <td style={!hasFullAccess ? { color: 'transparent', textShadow: "0 0 8px #000" } : null}>${hasFullAccess ? this.convertValues(parseFloat(bond['interest']).toFixed(2)) : "0.00"}</td>
                                    <td style={!hasFullAccess ? { position: 'relative', color: 'transparent', textShadow: "0 0 8px #000" } : { position: 'relative' }}>
                                        ${hasFullAccess ? this.convertValues(parseFloat(bond['value']).toFixed(2)) : "0.00"}
                                        <button onClick={() => this.handleClickX(i)} className={windowHeight < 500 ? 'close-mobile' : 'close'}>
                                            <span aria-hidden='true' style={{ border: 'none' }}>&times;</span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                <div style={{ height: '2rem', width: '100%', position: 'relative' }}>
                    {bonds.length > 0 ?
                        <div style={{ position: 'absolute', top: '.5rem', right: 0 }}>
                            <p style={{ float: 'left', margin: '.2rem .5rem .25rem 0', fontSize: '.875rem' }}>Showing</p>
                            <Form.Control name="per-page" onChange={this.props.setPerPage} size="sm" type="text" min="1" max="1000" defaultValue={this.props.perPage} style={{ float: 'left', width: '3rem', height: '1.7rem', textAlign: 'center', padding: '0' }} />
                            <p style={{ float: 'left', margin: '.2rem 0 .25rem .5rem', fontSize: '.875rem' }}>per page</p>
                        </div>
                        : null}
                    {this.props.filteredBonds.length > this.props.perPage ?
                        <Pagination size='sm' style={{ marginTop: '-1px' }}>
                            <Pagination.Prev onClick={this.props.setPrevPage} />
                            {this.props.pages.map((i) => (
                                <Pagination.Item
                                    key={i}
                                    active={i === this.props.currentPage}
                                    onClick={() => this.props.setCurrentPage(i)}
                                >
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={this.props.setNextPage} />
                        </Pagination> : null
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default BondTable;