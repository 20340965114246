import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import LogoutLink from '../../components/logout-link';
import { Helmet } from "react-helmet";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import setClass from '../../setClassName';
import "../../styles/tax-pricing.css";

const cookies = new Cookies();

const single = {
    10: '$0 – $9,700',
    12: '$9, 701 – $39,475',
    22: '$39, 476 – $84,200',
    24: '$84, 201 – $160,725',
    32: '$160, 726 – $204,100',
    35: '$204, 101 – $510,300',
    37: '$510, 301 or more'
}

const married_joint = {
    10: '$0 – $19,400',
    12: '$19,401 – $78,950',
    22: '$78,951 – $168,400',
    24: '$168,401 – $321,450',
    32: '$321,451 – $408,200',
    35: '$408,201 – $612,350',
    37: '$612,351 or more'
}

const married_separate = {
    10: '$0 – $9,700',
    12: '$9,701 – $39,475',
    22: '$39,476 – $84,200',
    24: '$84,201 – $160,725',
    32: '$160,726 – $204,100',
    35: '$204,101 – $306,750',
    37: '$306,751 or more'
}

const head_of_household = {
    10: '$0 – $13,850',
    12: '$13,851 – $52,850',
    22: '$52,851 – $84,200',
    24: '$84,201 – $160,700',
    32: '$160,701 – $204,100',
    35: '$204,101 – $510,300',
    37: '$510,301 or more'
}

class TaxCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: window.location.pathname,
            windowWidth: window.outerWidth,
            currentFiling: 'single',
            currentIncomeRanges: [
                '$0 – $9,700',
                '$9, 701 – $39,475',
                '$39, 476 – $84,200',
                '$84, 201 – $160,725',
                '$160, 726 – $204,100',
                '$204, 101 – $510,300',
                '$510, 301 or more'
            ],
            denominationOptions: [50, 75, 100, 200, 500, 1000, 5000, 10000],
            taxResults: [],
            currentDate: null,
            totalValue: null,
            totalInterest: null,
            totalTax: null,
            isLoggedIn: false
        }

        this.handleResize = this.handleResize.bind(this)
        this.onFilingChange = this.onFilingChange.bind(this)
        this.onClear = this.onClear.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (window.outerWidth > 0 && window.outerHeight > 0) {
            this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight })
        }
        else {
            this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
        }

        if (cookies.get('inCookie') === 'in') { this.setState({ isLoggedIn: true }) }
    }

    handleResize() {
        setTimeout(() => {
            if (window.outerWidth > 0 && window.outerHeight > 0) {
                this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight })
            }
            else {
                this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
            }
        }, 25);
    }

    getCurrentDate() {
        const months = { "1": "Jan.", "2": "Feb.", "3": "Mar.", "4": "April", "5": "May", "6": "June", "7": "July", "8": "Aug.", "9": "Sept.", "10": "Oct.", "11": "Nov.", "12": "Dec." }

        let d = new Date();

        let month = d.getMonth() + 1
        let year = d.getFullYear()

        return months[month] + ' ' + year.toString()
    }

    onFilingChange(e) {
        let currentFiling = e.target.value

        let incomeRanges = []

        if (currentFiling === 'single') {
            for (let key in single) {
                incomeRanges.push(single[key])
            }
        }
        else if (currentFiling === 'married_joint') {
            for (let key in married_joint) {
                incomeRanges.push(married_joint[key])
            }
        }
        else if (currentFiling === 'married_separate') {
            for (let key in married_separate) {
                incomeRanges.push(married_separate[key])
            }
        }
        else if (currentFiling === 'head_of_household') {
            for (let key in head_of_household) {
                incomeRanges.push(head_of_household[key])
            }
        }

        this.setState({ currentIncomeRanges: incomeRanges })
    }

    addTotalValues() {
        const bondValues = document.getElementsByClassName('bond-value')

        let values = []

        for (let i in bondValues) {
            let value = bondValues[i].innerHTML

            if (typeof value !== "undefined") {
                value = parseFloat(value.replace('$', ''))

                console.log(value)

                values.push(value)
            }
        }

        return values.reduce((a, b) => a + b, 0)
    }

    addTotalInterest() {
        const bondInterests = document.getElementsByClassName('bond-interest')

        let values = []

        for (let i in bondInterests) {
            let value = bondInterests[i].innerHTML

            if (typeof value !== "undefined") {
                value = parseFloat(value.replace('$', ''))

                console.log(value)

                values.push(value)
            }
        }

        return values.reduce((a, b) => a + b, 0)
    }

    addTotalTaxes() {
        const bondTaxes = document.getElementsByClassName('bond-tax')

        let values = []

        for (let i in bondTaxes) {
            let value = bondTaxes[i].innerHTML

            if (typeof value !== "undefined") {
                value = parseFloat(value.replace('$', ''))

                console.log(value)

                values.push(value)
            }
        }

        return values.reduce((a, b) => a + b, 0)
    }

    onClear(e) {
        e.preventDefault();

        this.setState({
            taxResults: [],
            currentDate: null,
            totalValue: null,
            totalInterest: null,
            totalTax: null
        })
    }

    onSubmit(e) {
        e.preventDefault();

        const { taxResults } = this.state

        let allResults = taxResults

        let filingType = null
        let filedAs = e.target.filedAs.value
        let incomeRange = e.target.incomeRange.value

        let filer = null

        if (filedAs === 'single') { filingType = single; filer = "Single" }
        else if (filedAs === 'married_joint') { filingType = married_joint; filer = "Married (joint)" }
        else if (filedAs === 'married_separate') { filingType = married_separate; filer = "Married (separate)" }
        else { filingType = head_of_household; filer = "Head of House" }

        let taxPercent = null

        for (let key in filingType) {
            if (filingType[key] === incomeRange) {
                taxPercent = parseFloat((parseInt(key) / 100))
            }
        }

        let currentDate = new Date();

        let submitTax = {
            'filer': filer,
            'incomeRange': incomeRange,
            'taxBracket': taxPercent,
            'valueMonth': currentDate.getMonth() + 1,
            'valueYear': currentDate.getFullYear(),
            'series': e.target.series.value,
            'denomination': parseInt(e.target.denomination.value),
            'issueMonth': parseInt(e.target.issueMonth.value),
            'issueYear': parseInt(e.target.issueYear.value)
        }

        console.log(submitTax)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ submitTax })
        };

        //http://localhost:5000/tax
        //https://secure.savingsbondsolutions.com/tax

        fetch('https://secure.savingsbondsolutions.com/tax', requestOptions)
            .then(response => response.json())
            .then(response => {
                allResults.push(response)

                this.setState({ taxResult: allResults })

                this.setState({
                    currentDate: this.getCurrentDate(),
                    totalValue: this.addTotalValues(),
                    totalInterest: this.addTotalInterest(),
                    totalTax: this.addTotalTaxes()
                })
            })
    }

    onSeriesChange() {
        let series = document.getElementsByName('series')[0].value

        if (series === 'S') {
            this.setState({ denominationOptions: [25, 50, 75, 100] })
        }
        else if (series === 'E') {
            this.setState({ denominationOptions: [10, 25, 50, 75, 100, 200, 500, 1000, 5000, 10000] })
        }
        else {
            this.setState({ denominationOptions: [25, 50, 75, 100, 200, 500, 1000, 5000, 10000] })
        }
    }

    convertValues(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    render() {
        const { taxResults, totalValue, totalInterest, totalTax, currentDate, windowWidth, currentPage } = this.state

        return (
            <React.Fragment>
                <LogoutLink windowWidth={windowWidth} currentPage={currentPage} isLoggedIn={this.state.isLoggedIn} />
                <h1 style={{ display: 'none' }}>Savings Bond Tax Calculator</h1>
                <h2 style={{ display: 'none' }}>Calculate the taxes owed when you cash in your US savings bonds.</h2>
                <div style={{ width: '90%', minWidth: (windowWidth * .75), margin: ' 2rem auto' }}>
                    <Row style={{ width: '100%' }}>
                        <Col style={{ paddingRight: '1rem' }}>
                            <Form onSubmit={this.onSubmit} id="bond-tax-form">
                                <Form.Label>File Taxes As</Form.Label>
                                <Form.Control
                                    className={setClass('filing-input')}
                                    onChange={this.onFilingChange}
                                    name="filedAs"
                                    as="select"
                                    custom
                                >
                                    <option value="single">Single Person</option>
                                    <option value="married_joint">Married (filing jointly)</option>
                                    <option value="married_separate">Married (filing separately)</option>
                                    <option value="head_of_household">Head of Household</option>
                                </Form.Control>
                                <Form.Label>Income Range</Form.Label>
                                <Form.Control className={setClass('bond-input')} onChange={this.onIncomeChange} name="incomeRange" as="select" custom>
                                    {this.state.currentIncomeRanges.map((incomeRange) => (
                                        <option key={incomeRange} value={incomeRange}>{incomeRange}</option>
                                    ))}
                                </Form.Control>
                                <Form.Label>Bond Series</Form.Label>
                                <Form.Control
                                    className={setClass('bond-input')}
                                    onChange={() => this.onSeriesChange()}
                                    name="series"
                                    as="select"
                                    custom
                                >
                                    <option value="EE">EE Bond</option>
                                    <option value="I">I Bond</option>
                                    <option value="E">E Bond</option>
                                    <option value="S">Savings Note</option>
                                </Form.Control>
                                <Form.Label>Bond Denomination</Form.Label>
                                <Form.Control className={setClass('bond-input')} name="denomination" as="select" custom>
                                    {this.state.denominationOptions.map((opt) => (
                                        <option key={opt} value={opt}>${this.convertValues(opt)}</option>
                                    ))}
                                </Form.Control>
                                <Form.Label>Bond Issue Date</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        required
                                        name="issueMonth"
                                        type="number"
                                        placeholder="Mo"
                                        maxLength="2"
                                        defaultValue="5"
                                        min="1"
                                        max="12"
                                        className={this.state.errorMessage ? setClass('form-month') + ' highlight' : setClass('form-month')}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text className={setClass('form-seperator')}>/</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control
                                        required
                                        name="issueYear"
                                        type="number"
                                        placeholder="Year"
                                        maxLength="4"
                                        defaultValue="2000"
                                        max={this.state.currentYear}
                                        className={this.state.errorMessage ? setClass('form-year') + ' highlight' : setClass('form-year')}
                                    />
                                </InputGroup>
                                <Button id="clear-tax-calculator" type="button" size='sm' variant="success" className={setClass("tax-form-button")} onClick={this.onClear}>CLEAR</Button>
                                <Button id="submit-tax-calculator" type="submit" size='sm' variant="primary" className={setClass("tax-form-submit")}>SUBMIT</Button>
                            </Form>
                        </Col>
                        <Col style={{ flex: 4, paddingTop: '2rem', paddingLeft: '2rem' }}>
                            <Row>
                                <Col style={{ paddingLeft: 0 }}>
                                    <Card className='card-tax'>
                                        <Card.Header className={setClass('card-tax-header')}>VALUE DATE</Card.Header>
                                        <Card.Body className={setClass('card-tax-body')}>
                                            {currentDate ?
                                                <Card.Title className={setClass('card-tax-title')}>{currentDate}</Card.Title>
                                                : null}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>

                                    <Card className='card-tax'>
                                        <Card.Header className={setClass('card-tax-header')}>TOTAL VALUE</Card.Header>
                                        <Card.Body className={setClass('card-tax-body')}>
                                            {totalValue ?
                                                <Card.Title className={setClass('card-tax-title')} style={{ color: 'green' }}>${totalValue.toFixed(2)}</Card.Title>
                                                : null}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>

                                    <Card className='card-tax'>
                                        <Card.Header className={setClass('card-tax-header')}>TOTAL INTEREST</Card.Header>
                                        <Card.Body className={setClass('card-tax-body')} >
                                            {totalInterest ?
                                                <Card.Title className={setClass('card-tax-title')} style={{ color: '#ff8c00' }}>${totalInterest.toFixed(2)}</Card.Title>
                                                : null}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col style={{ paddingRight: 0 }}>
                                    <Card className='card-tax'>
                                        <Card.Header className={setClass('card-tax-header')}>TOTAL TAX</Card.Header>
                                        <Card.Body className={setClass('card-tax-body')} >
                                            {totalTax ?
                                                <Card.Title className={setClass('card-tax-title')} style={{ color: 'red' }}>${totalTax.toFixed(2)}</Card.Title>
                                                : null}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Table className="bond-tax-table" striped>
                                    <thead>
                                        <tr>
                                            <th>Filed As</th>
                                            <th>Income Range</th>
                                            <th>Issue Date</th>
                                            <th>Series</th>
                                            <th>Denomination</th>
                                            <th>Bond Value</th>
                                            <th>Bond Interest</th>
                                            <th>Bond Tax</th>
                                        </tr>
                                    </thead>
                                    {taxResults.length > 0 ?
                                        <tbody>
                                            {taxResults.map((taxResult, i) => (
                                                <tr key={i}>
                                                    <td style={{ textAlign: 'left' }}>{taxResult['filer']}</td>
                                                    <td style={{ textAlign: 'left' }}>{taxResult['incomeRange']}</td>
                                                    <td>{taxResult['issueDate']}</td>
                                                    <td>{taxResult['series']}</td>
                                                    <td>${taxResult['denomination']}</td>
                                                    <td className="bond-value">${taxResult['value']}</td>
                                                    <td className="bond-interest">${taxResult['interest']}</td>
                                                    <td className="bond-tax">${taxResult['tax']}</td>
                                                </tr>))}
                                        </tbody>
                                        : null}
                                </Table>
                            </Row>
                        </Col>
                    </Row>
                    <Helmet>
                        <title>Savings Bond Tax Calculator - Savings Bond Solutions</title>
                        <meta name='description' content="Determine the taxes owed on your savings bonds. Enter how you file, federal tax bracket and bond details to find out!" />
                        <meta name="keywords" content="savings bond tax calculator, savings bond tax, savings bond interest tax" />
                    </Helmet>
                </div>
            </React.Fragment>
        );
    }
}

export default TaxCalculator;