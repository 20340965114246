import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import setClass from '../../setClassName';
import "../../styles/pricing.css";

class BondCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCards: { visibility: 'hidden' }
        }
    }

    convertValues(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    render() {
        const { totalDate, totalDenom, totalPrice, totalInterest, totalValue, windowHeight, hasFullAccess } = this.props

        return (
            <Form style={this.props.windowWidth < 1024 ? { margin: '.5rem 0' } : { margin: '1.25rem 0' }}>
                <Form.Row>
                    <Col>
                        <Card>
                            <Card.Header className={setClass('card-header')}>VALUE DATE</Card.Header>
                            <Card.Body className={setClass('card-body')} >
                                <Card.Title className={setClass('card-title')}>{totalDate}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className={setClass('card-header')}>{windowHeight <= 320 ? 'T. FACE VALUE' : 'TOTAL FACE VALUE'}</Card.Header>
                            <Card.Body className={setClass('card-body')} >
                                <Card.Title id="total-face-value" className={setClass('card-title')}>${this.convertValues(totalDenom)}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className={setClass('card-header')}>TOTAL COST</Card.Header>
                            <Card.Body className={setClass('card-body')} >
                                <Card.Title id="total-cost" className={setClass('card-title')} style={{ color: 'red' }} >${this.convertValues(parseFloat(totalPrice).toFixed(2))}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className={setClass('card-header')}>{windowHeight <= 320 ? 'T. INTEREST' : 'TOTAL INTEREST'}</Card.Header>
                            <Card.Body className={setClass('card-body')}>
                                <Card.Title id="total-interest" className={setClass('card-title')} style={hasFullAccess ? { color: '#ff8c00' } : { color: 'transparent', textShadow: "0 0 8px #ff8c00" }}>${hasFullAccess ? this.convertValues(parseFloat(totalInterest).toFixed(2)) : "0.00"}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className={setClass('card-header')}>TOTAL VALUE</Card.Header>
                            <Card.Body className={setClass('card-body')}>
                                <Card.Title id="total-value" className={setClass('card-title')} style={hasFullAccess ? { color: 'green' } : { color: 'transparent', textShadow: "0 0 8px green" }}>${hasFullAccess ? this.convertValues(parseFloat(totalValue).toFixed(2)) : "0.00"}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Form.Row>
            </Form >
        );
    }
}

export default BondCards;