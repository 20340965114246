import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import LogoutLink from '../components/logout-link';
import ReCAPTCHA from "react-google-recaptcha";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import '../styles/contact.css';

const cookies = new Cookies();
const recaptchaRef = React.createRef();

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertMessage: null,
            alertType: null,
            windowType: 'desktop',
            windowWidth: window.outerWidth,
            currentPage: window.location.pathname,
            isLoggedIn: false
        }

        this.handleResize = this.handleResize.bind(this)
        this.onCaptchaChange = this.onCaptchaChange.bind(this)
        this.onContactSubmit = this.onContactSubmit.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (window.outerWidth < 1024 || window.innerWidth < 1024) {
            this.setState({ windowType: 'mobile' })
        }
    }

    handleResize() {
        setTimeout(() => {
            if (window.outerWidth < 1024 || window.innerWidth < 1024) {
                this.setState({ windowType: 'mobile' })
            }
            else {
                this.setState({ windowType: 'desktop' })
            }
        }, 25);
    }

    resetAlert() {
        setTimeout(function () {
            this.setState({ alertMessage: null, alertType: null })
        }.bind(this), 5000);
    }

    onCaptchaChange() {
        this.setState({ alertMessage: null, alertType: null })
    }

    onContactSubmit(e) {
        e.preventDefault()

        const recaptchaValue = recaptchaRef.current.getValue();

        let username = e.target.username.value
        let email = e.target.email.value
        let message = e.target.message.value

        if (!recaptchaValue) {
            this.setState({ alertMessage: "Before sending, please complete the reCAPTCHA below!", alertType: 'danger' })
            this.resetAlert();
        }
        else if (!username || !email || !message) {
            this.setState({ alertMessage: "Before sending, please fill out all fields below!", alertType: 'warning' })
            this.resetAlert();
        }
        else {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({
                    'username': e.target.username.value,
                    'email': e.target.email.value,
                    'message': e.target.message.value
                })
            };

            //http://localhost:5000/contact-us
            //https://secure.savingsbondsolutions.com/contact-us

            fetch('https://secure.savingsbondsolutions.com/contact-us', requestOptions)

            this.setState({ alertMessage: 'Thank you!  Your message will be read and responded to shortly.', alertType: 'success' })

            document.getElementsByName('username')[0].value = ""
            document.getElementsByName('email')[0].value = ""
            document.getElementsByName('message')[0].value = ""

            recaptchaRef.current.reset();
            this.resetAlert();
        }
    }

    componentWillMount() {
        if (cookies.get('inCookie') === 'in') { this.setState({ isLoggedIn: true }) }
    }

    render() {
        const { windowWidth, currentPage, windowType, alertMessage, alertType } = this.state

        return (
            <React.Fragment>
                <LogoutLink windowWidth={windowWidth} currentPage={currentPage} isLoggedIn={this.state.isLoggedIn} />
                <div style={{ marginTop: '2rem' }}>
                    <Form onSubmit={this.onContactSubmit} className={'contact-form-' + windowType}>
                        <Form.Row>
                            <h1 style={{ fontSize: '2.25rem' }}>CONTACT US</h1>
                            <p style={{ marginTop: '.5rem' }}>Feel free to email us any time, we would love to hear your feedback and suggestions!</p>
                        </Form.Row>
                        <Form.Row style={{ position: 'relative', width: '101.5%' }}>
                            {alertMessage ? <Alert variant={alertType} style={{ width: '100%', position: 'absolute', top: '-.25rem', paddingTop: '.25rem', paddingBottom: '.25rem', textAlign: 'center' }}>{alertMessage}</Alert> : null}
                        </Form.Row>
                        <Form.Row style={{ marginTop: '2.5rem' }}>
                            <Col style={{ paddingLeft: 0 }}>
                                <Form.Control
                                    name="username"
                                    type="text"
                                    style={{ width: '75%' }}
                                    placeholder="Your Name"
                                    onFocus={(e) => e.target.placeholder = ""}
                                    onBlur={(e) => e.target.placeholder = "Your Name"}
                                />
                            </Col>
                            <Col style={{ flex: .75, paddingRight: 0 }}>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="Your Email"
                                    onFocus={(e) => e.target.placeholder = ""}
                                    onBlur={(e) => e.target.placeholder = "Your Email"}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row style={{ marginTop: '1rem' }}>
                            <Form.Control
                                name="message"
                                as="textarea"
                                placeholder="Your Message"
                                onFocus={(e) => e.target.placeholder = ""}
                                onBlur={(e) => e.target.placeholder = "Your Message"}
                                rows={10} />
                        </Form.Row>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LdUo-QZAAAAALI56ouwNNzTAdYMH-K5NklvUVTJ"
                            onChange={this.onCaptchaChange}
                            style={{ float: 'left', marginTop: '1rem', marginLeft: '-.25rem' }}
                        />
                        <Button
                            size='sm'
                            type="submit"
                            style={{ float: 'right', marginTop: '1rem', marginRight: '-.25rem', padding: '.5rem 1.5rem' }}>
                            SEND
                    </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

export default Contact;