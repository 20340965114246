import React, { Component } from 'react';
// import GoogleLogin from 'react-google-login';
import ReCAPTCHA from "react-google-recaptcha";
import bcrypt from 'bcryptjs';
import Cookies from 'universal-cookie';
import Alert from 'react-bootstrap/Alert';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import GreenCheck from "../images/green-check.png";
import RedCross from "../images/red-cross.png";
import HidePassword from "../images/hide-password.png";
import ShowPassword from "../images/show-password.png";
import Logo from '../images/bond-logo.png';
import '../styles/login.css';

const cookies = new Cookies();
const recaptchaRef = React.createRef();

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: { "check": false, "cross": false, "error": null },
            email: { "check": false, "cross": false, "error": null },
            password: { "check": false, "cross": false, "error": null },
            repeat: { "check": false, "cross": false, "error": null },
            activeTab: 'login',
            pwd: "",
            rpt: "",
            hash: null,
            message: null,
            showAlert: false,
            showPassword: false,
        };

        this.handleName = this.handleName.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        this.handleEmail = this.handleEmail.bind(this)
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onChangeRepeat = this.onChangeRepeat.bind(this)
        this.handleRegister = this.handleRegister.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this)
        this.setActiveTab = this.setActiveTab.bind(this)
        this.encryptPassword = this.encryptPassword.bind(this)
        this.showAndHidePassword = this.showAndHidePassword.bind(this)
        this.setMessage = this.setMessage.bind(this)
    }

    setMessage(msgText, msgAlert, timeout) {
        this.setState({ showAlert: true, message: { 'text': msgText, 'alert': msgAlert } })

        setTimeout(() => { this.setState({ showAlert: false }); }, timeout);

    }

    componentDidMount() {
        const cookies = new Cookies();
        cookies.remove('userCookie')

        let url = window.location.href.split("/")

        if (this.props.activeTab) {
            this.setState({ activeTab: this.props.activeTab })

            this.setMessage('Please login to use this feature.', 'warning', 4000)
        }
        else {
            this.setState({ activeTab: url[url.length - 1] })
        }

        let ref = (document.referrer).split("/")


        if (ref[ref.length - 1] === 'register') {
            this.setState({ activeTab: 'register' })
        }

        if (ref[ref.length - 1] === 'password-reset') {
            this.setMessage('Password updated successfully.', 'success', 3000)
        }

        if (cookies.get("forgottenCookies") !== undefined) {
            document.getElementsByName('loginEmail')[0].value = cookies.get("forgottenCookies")
        }
    }

    showAndHidePassword() {
        const { showPassword } = this.state

        this.setState({ showPassword: !showPassword })
    }

    encryptPassword(password) {
        bcrypt.genSalt(10, function (err, salt) {
            bcrypt.hash(password, salt, function (err, hash) {
                this.setState({ hash: hash })
            }.bind(this));
        }.bind(this));
    }

    handleName(e) {
        let name = e.target.value

        if (name.length > 0) {
            this.setState({ name: { "check": true, "cross": false, "error": null } })
        }
        else {
            this.setState({ name: { "check": false, "cross": true, "error": "Please enter a valid name." } })
        }
    }

    onChangeName(e) {
        const { name } = this.state

        let currentName = e.target.value

        if (currentName.length === 0) {
            this.setState({ name: { "check": false, "cross": false, "error": null } })
        }
        else if (currentName.length > 0 && name['error']) {
            this.setState({ name: { "check": false, "cross": false, "error": null } })
        }
    }

    handleEmail(e) {
        let email = e.target.value

        let regex = /^[a-zA-z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

        if (email.length === 0) {
            this.setState({ email: { "check": false, "cross": false, "error": null } })
        }
        else if (regex.test(email)) {
            this.setState({ email: { "check": true, "cross": false, "error": null } })
        }
        else {
            this.setState({ email: { "check": false, "cross": true, "error": "Invalid email format." } })
        }
    }

    onChangeEmail(e) {
        const { email } = this.state

        let currentEmail = e.target.value

        if (currentEmail.length === 0) {
            this.setState({ email: { "check": false, "cross": false, "error": null } })
        }
        else if (currentEmail.length > 0 && email['error']) {
            this.setState({ email: { "check": false, "cross": false, "error": null } })
        }
    }

    parsePassword(password) {
        let pwdSplit = password.split("")

        let letters = []
        let numbers = []

        for (let i in pwdSplit) {
            let char = pwdSplit[i]

            if (!isNaN(char)) {
                numbers.push(char)
            }
            else {
                letters.push(char)
            }
        }

        if (letters.length >= 5 && numbers.length > 0) {
            return true
        }
        else {
            return false
        }
    }

    onChangePassword(e) {
        const { rpt } = this.state
        let password = e.target.value

        let valid = this.parsePassword(password)

        if (password.length === 0) {

            this.setState({ password: { "check": false, "cross": false, "error": null } })

            if (rpt.length > 0) {
                this.setState({ repeat: { "check": false, "cross": true, "error": "Passwords do not match." } })
            }
        }
        else {
            if (valid) {
                this.setState({ password: { "check": true, "cross": false, "error": null } })

                if (rpt.length === 0) {
                    this.setState({ repeat: { "check": false, "cross": false, "error": null } })
                }
                else if (rpt === password) {
                    this.setState({ repeat: { "check": true, "cross": false, "error": null } })
                }
                else {
                    this.setState({ repeat: { "check": false, "cross": true, "error": "Passwords do not match." } })
                }
            }
            else {
                this.setState({ password: { "check": false, "cross": true, "error": "Must contain at least five letters and a number." } })
            }
        }

        this.setState({ pwd: password })
    }

    onChangeRepeat(e) {
        const { pwd } = this.state

        let repeat = e.target.value

        if (repeat.length === 0) {
            this.setState({ repeat: { "check": false, "cross": false, "error": null } })
        }
        else {
            if (pwd === repeat) {
                this.setState({ repeat: { "check": true, "cross": false, "error": null } })

                this.encryptPassword(pwd)
            }
            else {
                this.setState({ repeat: { "check": false, "cross": true, "error": "Passwords do not match." } })
            }
        }

        this.setState({ rpt: repeat })
    }

    handleRegister(e) {
        e.preventDefault();

        const { name, email, password, repeat, hash } = this.state

        let formName = e.target.regName.value
        let formEmail = (e.target.regEmail.value).toLowerCase();
        let formPassword = e.target.regPassword.value

        if (!name["check"] || !email["check"] || !password["check"] || !repeat["check"]) {
            this.setMessage("One or more of the required fields are invalid.", "danger", 3000)
        }
        else if (name["check"] && email["check"] && password["check"] && repeat["check"]) {

            let createAccount = {
                'name': formName,
                'email': formEmail,
                'password': hash
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({ createAccount })
            };

            // http://localhost:5000/register
            // https://secure.savingsbondsolutions.com/register

            fetch('https://secure.savingsbondsolutions.com/register', requestOptions)
                .then(response => response.json())
                .then(response => {
                    this.setState({ activeTab: 'login' })

                    this.setMessage(response['text'], response['alert'], 5000)
                    document.getElementsByName('loginEmail')[0].value = formEmail
                    document.getElementsByName('loginPassword')[0].value = formPassword

                    let regForm = document.getElementById('register-form')
                    let input = regForm.getElementsByTagName('input')

                    for (let i = 0; i < input.length; i++) {
                        input[i].value = ""
                    }

                    this.setState({
                        name: { "check": false, "cross": false, "error": null },
                        email: { "check": false, "cross": false, "error": null },
                        password: { "check": false, "cross": false, "error": null },
                        repeat: { "check": false, "cross": false, "error": null }
                    })
                })
        }
    }

    handleLogin(e) {
        e.preventDefault()

        const recaptchaValue = recaptchaRef.current.getValue();

        if (!recaptchaValue) {
            this.setMessage("Please complete the reCAPTCHA below!", "danger", 3000)
            return
        }

        let email = (e.target.loginEmail.value).toLowerCase();
        let password = e.target.loginPassword.value

        let loginAccount = {
            'email': email,
            'type': 'manual'
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ loginAccount })
        };

        // http://localhost:5000/login
        // https://secure.savingsbondsolutions.com/login

        fetch('https://secure.savingsbondsolutions.com/login', requestOptions)
            .then(response => response.json())
            .then(response => {
                this.setState({ message: response })

                if (response['alert'] === 'danger') {
                    this.setState({ showAlert: true });
                    setTimeout(() => { this.setState({ showAlert: false }); }, 5000);
                }
                else {
                    let hash = response['password']

                    bcrypt.compare(password, hash, function (err, res) {
                        if (err) {
                            return err
                        }

                        if (res) {
                            let name = response['name'].split(" ")

                            cookies.set('inCookie', 'in', { path: '/', expires: new Date(Date.now() + (60000 * 120)) });
                            cookies.set('userFullName', response['name'], { path: '/', expires: new Date(Date.now() + (60000 * 120)) });
                            cookies.set('userName', name[0], { path: '/', expires: new Date(Date.now() + (60000 * 120)) });
                            cookies.set('userEmail', response['email'], { path: '/', expires: new Date(Date.now() + (60000 * 120)) });
                            cookies.set('userType', 'manual', { path: '/', expires: new Date(Date.now() + (60000 * 120)) });

                            if (localStorage.getItem("getFullAccess") === "true") {
                                window.location.href = "/account-options"

                                localStorage.removeItem("getFullAccess")
                            }
                            else {
                                if (response['accountType'] === 0) {
                                    window.location.href = "/calculators/savings-bond-calculator-free"
                                }
                                else {
                                    window.location.href = "/calculators/savings-bond-calculator"
                                }
                            }
                        }
                        else {
                            this.setState({ showAlert: true, message: { 'text': 'Account name or password is incorrect.', 'alert': 'danger' } })
                            setTimeout(() => { this.setState({ showAlert: false }); }, 3000);
                        }
                    }.bind(this));
                }
            });

        recaptchaRef.current.reset();
    }

    handleGoogleLogin(response) {
        cookies.set('inCookie', 'in', { path: '/', expires: new Date(Date.now() + (60000 * 120)) });
        cookies.set('userFullName', response.profileObj.name, { path: '/', expires: new Date(Date.now() + (60000 * 120)) });
        cookies.set('userName', response.profileObj.givenName, { path: '/', expires: new Date(Date.now() + (60000 * 120)) });
        cookies.set('userEmail', response.profileObj.email, { path: '/', expires: new Date(Date.now() + (60000 * 120)) });
        cookies.set('userType', 'google', { path: '/', expires: new Date(Date.now() + (60000 * 120)) });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ 'userEmail': response.profileObj.email, 'userName': response.profileObj.name })
        };

        // http://localhost:5000/register-google-user
        // https://secure.savingsbondsolutions.com/register-google-user

        fetch('https://secure.savingsbondsolutions.com/register-google-user', requestOptions)
            .then(response => response.json())
            .then(response => {
                console.log(response)
            })

        if (localStorage.getItem("getFullAccess") === "true") {
            window.location.href = "/account-options"

            localStorage.removeItem("getFullAccess")
        }
        else {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({ 'email': response.profileObj.email, 'accountType': 'google' })
            };

            //http://localhost:5000/check-subscription
            //https://secure.savingsbondsolutions.com/check-subscription

            fetch('https://secure.savingsbondsolutions.com/check-subscription', requestOptions)
                .then(response => response.json())
                .then(response => {
                    window.location.href = "/calculators/savings-bond-calculator"
                });
        }
    }

    handleGoogleLoginFailure() {
        return
    }

    setActiveTab(key) {
        if (key === 'register') {
            this.setState({ activeTab: 'register' })
        }
        else if (key === 'login') {
            this.setState({ activeTab: 'login' })
        }
    }

    render() {
        const { name, email, password, repeat, activeTab, message, showAlert, showPassword } = this.state

        return (
            <div id="login-page" style={{ width: '100%' }}>
                <div style={{ marginTop: '.5rem' }}>
                    {message ?
                        <Alert variant={message['alert']} className={showAlert ? 'alert-show' : 'alert-hidden'} style={{ marginBottom: '0', paddingTop: '.25rem', paddingBottom: '.25rem' }}>{message['text']}</Alert>
                        :
                        <Alert className={'alert-hidden'} style={{ marginBottom: '0', paddingTop: '.25rem', paddingBottom: '.25rem' }}>Placeholder</Alert>
                    }
                </div>
                <Tabs defaultActiveKey='register' activeKey={activeTab} onSelect={this.setActiveTab} style={{ width: '23rem', margin: 'auto', marginTop: '.5rem', borderBottom: 0 }}>
                    <Tab eventKey="register" title="Register">
                        <Form id="register-form" onSubmit={this.handleRegister} style={{ width: '23rem', padding: '2rem 2rem 1rem 2rem', margin: 'auto' }}>
                            <h5 style={{ fontSize: '1.25rem', textAlign: 'center', marginBottom: '1rem' }}>
                                <Image style={{ float: 'left', width: '1.5rem', marginTop: '-.25rem' }} src={Logo}></Image>
                                REGISTER
                                <Image style={{ float: 'right', width: '1.5rem', marginTop: '-.25rem' }} src={Logo}></Image>
                            </h5>
                            <Form.Group style={{ position: 'relative' }}>
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    name="regName"
                                    type="text"
                                    onBlur={this.handleName}
                                    onChange={this.onChangeName}
                                />
                                {name["check"] ? <Image className="register-checkmark" src={GreenCheck} alt="Green Check Email"></Image> : null}
                                {name["cross"] ? <Image className="register-cross" src={RedCross} alt="Red Cross Email"></Image> : null}
                                {name["error"] ? <Form.Text style={{ color: 'red', textAlign: 'center' }}>{name["error"]}</Form.Text> : null}
                            </Form.Group>
                            <Form.Group style={{ position: 'relative' }}>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    name="regEmail"
                                    type="email"
                                    onBlur={this.handleEmail}
                                    onChange={this.onChangeEmail}
                                />
                                {email["check"] ? <Image className="register-checkmark" src={GreenCheck} alt="Green Check Email"></Image> : null}
                                {email["cross"] ? <Image className="register-cross" src={RedCross} alt="Red Cross Email"></Image> : null}
                                {email["error"] ? <Form.Text style={{ color: 'red', textAlign: 'center' }}>{email["error"]}</Form.Text> : null}
                            </Form.Group>
                            <Form.Group style={{ position: 'relative' }}>
                                <Form.Label>Password:</Form.Label>
                                <Form.Control
                                    name="regPassword"
                                    type="password"
                                    onChange={this.onChangePassword}
                                />
                                {password["check"] ? <Image className="register-checkmark" src={GreenCheck} alt="Green Check Email"></Image> : null}
                                {password["cross"] ? <Image className="register-cross" src={RedCross} alt="Red Cross Email"></Image> : null}
                                {password["error"] ? <Form.Text style={{ color: 'red', textAlign: 'center' }}>{password["error"]}</Form.Text> : null}
                            </Form.Group>
                            <Form.Group style={{ position: 'relative' }}>
                                <Form.Label>Repeat Password:</Form.Label>
                                <Form.Control
                                    name="regRepeatPassword"
                                    type="password"
                                    onChange={this.onChangeRepeat}
                                />
                                {repeat["check"] ? <Image className="register-checkmark" src={GreenCheck} alt="Green Check Email"></Image> : null}
                                {repeat["cross"] ? <Image className="register-cross" src={RedCross} alt="Red Cross Email"></Image> : null}
                                {repeat["error"] ? <Form.Text style={{ color: 'red', textAlign: 'center' }}>{repeat["error"]}</Form.Text> : null}
                            </Form.Group>
                            <Button
                                variant="primary"
                                type="submit"
                                style={{ borderRadius: 0, width: '100%', fontSize: '.85rem', fontWeight: 'bold', marginTop: '.5rem' }}>
                                Register
                            </Button>
                        </Form>
                    </Tab>
                    <Tab eventKey="login" title="Login">
                        <Form id="login-form" onSubmit={this.handleLogin} style={{ width: '23rem', padding: '2rem', margin: 'auto' }}>
                            <h5 style={{ fontSize: '1.25rem', textAlign: 'center', marginBottom: '2rem' }}>
                                <Image style={{ float: 'left', width: '1.5rem', marginTop: '-.25rem' }} src={Logo}></Image>
                                LOGIN
                                <Image style={{ float: 'right', width: '1.5rem', marginTop: '-.25rem' }} src={Logo}></Image>
                            </h5>
                            <Form.Group>
                                <Form.Label>Email Address:</Form.Label>
                                <Form.Control name="loginEmail" type="email" />
                            </Form.Group>
                            <Form.Group style={{ position: 'relative' }}>
                                <Form.Label>Password:</Form.Label>
                                <Form.Control name="loginPassword" type={showPassword ? "text" : "password"} />
                                <Image src={showPassword ? ShowPassword : HidePassword} onClick={this.showAndHidePassword} style={{ position: 'absolute', top: '2.65rem', right: '.5rem', width: '1.1rem', cursor: 'pointer' }}></Image>
                                <Form.Text style={{ float: 'right' }}><a href="/forgot-password" style={{ padding: 0, fontSize: '.825rem' }}>Forgot Password?</a></Form.Text>
                            </Form.Group>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdUo-QZAAAAALI56ouwNNzTAdYMH-K5NklvUVTJ"
                                className="login-recaptcha"
                                style={{ float: 'left', marginTop: '2rem' }}
                            />
                            <Button variant="primary" type="submit" style={{ borderRadius: '0', width: '100%', fontSize: '.85rem', fontWeight: 'bold', marginTop: '1rem' }}>
                                Log in
                            </Button>
                        </Form>
                    </Tab>
                </Tabs>
                {/* <div style={{ width: '23rem', margin: 'auto', marginTop: '1rem' }}>
                    <GoogleLogin
                        className={'google-login'}
                        clientId="461806794042-t6ep0qobprdkp6eujm9i1apua2j356d6.apps.googleusercontent.com"
                        buttonText="Login with Google"
                        onSuccess={this.handleGoogleLogin}
                        onFailure={this.handleGoogleLoginFailure}
                    />
                </div> */}
            </div>
        );
    }
}

export default Login;