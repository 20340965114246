import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player/youtube';
import LogoutLink from '../components/logout-link';

const cookies = new Cookies();

class Tutorial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.outerWidth,
            currentPage: window.location.pathname,
            loadPlayer: false,
            isLoggedIn: false
        };

        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (window.outerWidth > 0) {
            this.setState({ windowWidth: window.outerWidth })
        }
        else {
            this.setState({ windowWidth: window.innerWidth })
        }

        this.setState({ loadPlayer: true });

        if (cookies.get('inCookie') === 'in') { this.setState({ isLoggedIn: true }) }
    }

    handleResize() {
        setTimeout(() => {
            if (window.outerWidth > 0) {
                this.setState({ windowWidth: window.outerWidth })
            }
            else {
                this.setState({ windowWidth: window.innerWidth })
            }
        }, 25);
    }

    render() {
        const { windowWidth, currentPage, loadPlayer } = this.state

        return (
            <React.Fragment>
                <LogoutLink windowWidth={windowWidth} currentPage={currentPage} isLoggedIn={this.state.isLoggedIn} />
                <div>
                    <div style={{ width: '50rem', margin: '2rem auto' }}>
                        <h1 style={{ marginBottom: '2rem', fontSize: '1.5rem' }}>Watch a quick intructional video on how to use our savings bond calculator.</h1>
                        {loadPlayer ? <ReactPlayer url='https://youtu.be/4DR1zzQ-huI' controls={true} height={"30rem"} width={"50rem"} /> : null}
                    </div>
                </div>
                <Helmet>
                    <title>Savings Bond Calculator - Tutorial</title>
                    <meta name='description' content="Need some guidance with how to use our savings bond calculator?  Watch this short instructional tutorial to help answer your questions!" />
                    <meta name="keywords" content="savings bond calculator, savings bond calculator online, tutorial" />
                </Helmet>
            </React.Fragment>
        );
    }
}

export default Tutorial;