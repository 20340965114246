import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Footer extends Component {
    state = {}
    render() {
        return (
            <div style={{ padding: '1rem 2rem', backgroundColor: 'rgba(0,0,0,.03)', borderTop: '1px solid rgba(0,0,0,.125)' }}>
                <Row style={{ textAlign: 'center', fontSize: '.875rem', width: '100%', margin: 'auto' }}>
                    <Col style={{ flex: .4 }}><a href="/">Home</a></Col>
                    <Col style={{ flex: .75 }}><a href="/tutorial">Tutorial</a></Col>
                    <Col><a href="/calculators/savings-bond-calculator">Savings Bond Calculator </a></Col>
                    <Col><a href="/calculators/savings-bond-tax-calculator">Savings Bond Tax Calculator</a></Col>
                    <Col><a href="/contact">Contact</a></Col>
                </Row>
                <div style={{ textAlign: 'center', marginTop: '2.5rem' }}>
                    {/* <p>Have a Question? <span style={{ marginLeft: '.5rem' }}><a href="mailto:contact@savingsbondsolutions.com">contact@savingsbondsolutions.com</a></span></p> */}
                    <p style={{ marginTop: '2.5rem', marginBottom: '.25rem', fontSize: '.875rem', fontWeight: '500' }}>Savings Bond Solutions | 2020</p>
                    <p style={{ fontSize: '.675rem', marginBottom: '1rem' }}>Copyright © All Rights Reserved</p>
                </div>
            </div>
        );
    }
}

export default Footer;