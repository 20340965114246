import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import {
    SquarePaymentForm,
    CreditCardNumberInput,
    CreditCardExpirationDateInput,
    CreditCardPostalCodeInput,
    CreditCardCVVInput,
    CreditCardSubmitButton
} from 'react-square-payment-form';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
// import BuiltWithSquare from '../images/built-with-square.png';
import SecurePayments from '../images/secure-payments.png';
import Visa from '../images/visa.png';
import MasterCard from '../images/mastercard.png';
import Amex from '../images/amex.png';
import Discover from '../images/discover.png';
// import BondLogo from '../images/bond-logo.png';
// import LogoutLink from './logout-link';
import 'react-square-payment-form/lib/default.css';
import '../styles/account-options.css';

const cookies = new Cookies();

class SquareSpace extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardType: null,
            cardNumberValue: "",
            cardMaxLength: 19,
            errorMessages: [],
            creditCards: {
                'visa': Visa,
                'mastercard': MasterCard,
                'amex': Amex,
                'discover': Discover,
            },
            paymentAmount: 0,
            userEmail: null,
            userType: null,
            showAlert: false,
            windowWidth: window.outerWidth,
            currentPage: window.location.pathname,
            isLoggedIn: false
        }

        this.onCreditCardKeyPress = this.onCreditCardKeyPress.bind(this)
        this.onCreditCardChange = this.onCreditCardChange.bind(this)
        this.setPaymentAmount = this.setPaymentAmount.bind(this)
        this.onPaymentKeyPress = this.onPaymentKeyPress.bind(this)
        this.createVerificationDetails = this.createVerificationDetails.bind(this)
    }

    componentDidMount() {
        this.setState({
            userEmail: cookies.get('userEmail'),
            userType: cookies.get('userType')
        });

        if (cookies.get('inCookie') === 'in') { this.setState({ isLoggedIn: true }) }


        // if (cookies.get('paymentPlan') === 'limited') {
        //     this.setState({
        //         paymentPlan: 'LIMITED ACCESS PASS',
        //         paymentPlanCash: '$1.99',
        //         paymentAmount: 199
        //     });
        // }
        // else if (cookies.get('paymentPlan') === 'unlimited') {
        //     this.setState({
        //         paymentPlan: 'FULL ACCESS PASS',
        //         paymentPlanCash: '$4.99',
        //         paymentAmount: 499
        //     });
        // }
    }

    onPaymentKeyPress(e) {
        if (e.charCode === 46) {
            e.preventDefault()
            return
        }
    }

    setPaymentAmount(e) {
        if (!e.target.value) {
            this.setState({ paymentAmount: 0 })
        }
        else {
            this.setState({ paymentAmount: e.target.value })
        }
    }


    onCreditCardKeyPress(e) {
        let cardNumber = e.target.value

        if (cardNumber.length === 4) {
            this.setState({ cardNumberValue: cardNumber.replace(/(.{4})/g, "$1 ") })
        }
        else if (cardNumber.length === 9) {
            this.setState({ cardNumberValue: cardNumber.replace(/(.{4}) (.{4})/g, "$1 $2 ") })
        }
        else if (cardNumber.length === 14) {
            this.setState({ cardNumberValue: cardNumber.replace(/(.{4}) (.{4}) (.{4})/g, "$1 $2 $3 ") })
        }
        else {
            this.setState({ cardNumberValue: e.target.value })
        }
    }

    onCreditCardChange(e) {
        const { cardType } = this.state

        let cardNumber = e.target.value
        this.setState({ cardNumberValue: cardNumber })

        if (cardNumber[0] === '3' && (cardNumber[1] === '4' || cardNumber[1] === '7') && !cardType) { this.setState({ cardType: 'amex' }) }
        if (cardNumber[0] === '4' && !cardType) { this.setState({ cardType: 'visa' }) }
        if (cardNumber[0] === '5' && !cardType) { this.setState({ cardType: 'mastercard' }) }
        if (cardNumber[0] === '6' && !cardType) { this.setState({ cardType: 'discover' }) }

        if (cardNumber[0] !== '3' && cardType === 'amex') { this.setState({ cardType: null }) }
        if (cardNumber[0] !== '4' && cardType === 'visa') { this.setState({ cardType: null }) }
        if (cardNumber[0] !== '5' && cardType === 'mastercard') { this.setState({ cardType: null }) }
        if (cardNumber[0] !== '6' && cardType === 'discover') { this.setState({ cardType: null }) }
    }

    cardNonceResponseReceived = (errors, nonce, cardData, buyerVerificationToken) => {
        if (errors) {
            this.setState({ errorMessages: errors.map(error => error.message) })

            if (this.state.paymentAmount === 0) {
                this.setState({
                    showAlert: true,
                    alertType: 'warning',
                    alertText: 'Please enter a minimum value of $1.00 before payment'
                });

                setTimeout(function () {
                    this.setState({ showAlert: false, alertType: null, alertText: null });
                }.bind(this), 5500)
            }

            return
        }

        this.setState({ errorMessages: [] })

        if (this.state.paymentAmount === 0) {
            this.setState({
                showAlert: true,
                alertType: 'warning',
                alertText: 'Please enter a minimum value of $1.00 below'
            });

            setTimeout(function () {
                this.setState({ showAlert: false, alertType: null, alertText: null });
            }.bind(this), 5500)
        }
        else {


            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({
                    'nonce': nonce,
                    'buyerToken': buyerVerificationToken,
                    'paymentAmount': parseInt(this.state.paymentAmount),
                    'userEmail': this.state.userEmail,
                    'userType': this.state.userType
                })
            };

            //http://localhost:5000/payment
            //https://secure.savingsbondsolutions.com/payment

            fetch('https://secure.savingsbondsolutions.com/payment', requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response['message'] === "success") {
                        this.setState({
                            showAlert: true,
                            alertType: 'success',
                            alertText: 'Success! You will be redirected shortly.'
                        });

                        setTimeout(function () {
                            window.location.href = "/calculators/savings-bond-calculator"
                        }, 1500)
                    }
                    else if (response['message'] === "failure") {
                        this.setState({
                            showAlert: true,
                            alertType: 'danger',
                            alertText: 'Payment failed. Check that your credit card information is correct.'
                        });

                        setTimeout(function () {
                            this.setState({ showAlert: false, alertType: null, alertText: null });
                        }.bind(this), 5500)
                    }
                });
        }
    }

    createVerificationDetails() {
        return {
            amount: (this.state.paymentAmount).toString(),
            currencyCode: "USD",
            intent: "CHARGE",
            billingContact: {
                familyName: "Smith",
                givenName: "John",
                email: "jsmith@example.com"
            }
        }
    }

    render() {
        // const { paymentPlan, paymentPlanCash } = this.state

        return (
            <div style={{ width: '100%' }}>
                {/* <LogoutLink windowWidth={windowWidth} currentPage={currentPage} isLoggedIn={this.state.isLoggedIn} /> */}

                <div style={{ width: '380px', margin: 'auto', paddingTop: '2rem' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ color: '#333', marginTop: '-.1rem' }}>
                            <p style={{ margin: 0, fontSize: '1rem', marginLeft: '.1rem' }}>QUICK & EASY</p>
                            <h1 style={{ marginTop: '-.4rem', fontSize: '2rem', fontWeight: 'bold' }}>CHECKOUT</h1>
                        </div>
                        {/* <Image src={BuiltWithSquare} style={{ float: 'right', width: '9.5rem', marginTop: '.1rem' }}></Image> */}
                    </div>

                    <div style={{ position: 'relative' }}>
                        {this.state.showAlert ?
                            <Alert
                                variant={this.state.alertType}
                                style={{ position: 'absolute', top: 0, padding: '.5rem', textAlign: 'center', fontSize: '.875rem', fontWeight: '500' }}>
                                {this.state.alertText}
                            </Alert>
                            : null}
                    </div>

                    {/* <div style={{ padding: '.75rem', border: '1px solid #dee2e6', borderRadius: '.25rem', marginBottom: '6rem' }}>
                        <Row>
                            <Col style={{ flex: .15, paddingRight: 0 }}>
                                <Image src={BondLogo} style={{ width: '3rem' }} />
                            </Col>
                            <Col style={{ paddingLeft: 0, whitespace: 'nowrap' }}>
                                {paymentPlanCash ? <p style={{ margin: 0, textAlign: 'right', fontSize: '1rem', fontWeight: '500', marginTop: '-.25rem', color: '#fe0000' }}>{paymentPlanCash}</p> : null}
                                {paymentPlan ? <p style={{ fontSize: '1.7rem', fontWeight: '500', margin: '0', fontStyle: 'italic', textAlign: 'right' }}>{paymentPlan}</p> : null}
                            </Col>
                        </Row>
                    </div> */}

                    <SquarePaymentForm
                        applicationId={"sq0idp-IO_1I5s39T5gD9yywaFYrw"}
                        locationId={"LPTYKDK6VE697"}
                        cardNonceResponseReceived={this.cardNonceResponseReceived}
                        createVerificationDetails={this.createVerificationDetails}
                    >
                        {/* <FormLabel style={{ fontSize: '14px', fontWeight: '500', lineHeight: '24px', marginBottom: '0' }}>CREDIT CARD</FormLabel>
                        <InputGroup style={{ position: 'relative' }}>
                            <FormControl
                                id="sq-card-number"
                                type="text"
                                autoCapitalize="off"
                                autoCorrect="off"
                                spellCheck="off"
                                pattern="\d*"
                                autoComplete="cc-number"
                                maxLength="19"
                                placeholder="• • • •  • • • •  • • • •  • • • •"
                                onChange={this.onCreditCardChange}
                                onKeyUp={this.onCreditCardKeyUp}
                                onKeyPress={this.onCreditCardKeyPress}
                                value={cardNumberValue}
                                style={{ padding: '1.75rem 1rem' }}
                            />
                            {cardType ? <Image src={creditCards[cardType]} style={{ position: 'absolute', zIndex: '999', width: '3rem', top: '.875rem', right: '1rem' }}></Image> : null}
                        </InputGroup> */}

                        <FormLabel style={{ marginTop: '4rem', width: '100%', textAlign: 'left', fontSize: '14px', fontWeight: '500' }}>PAYMENT AMOUNT</FormLabel>
                        <InputGroup size="lg" style={{ marginTop: '-.25rem', marginBottom: '1rem' }}>
                            <InputGroup.Prepend>
                                <InputGroup.Text style={{ fontSize: '1.25rem', backgroundColor: "#fcfcfc", borderRight: 'none' }}> $</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                id="payment-amount"
                                type="number"
                                min="1"
                                style={{ padding: '1.75rem 1rem', fontSize: '1.25rem', borderLeft: '1px solid rgba(0,0,0, .075)', borderRight: '1px solid rgba(0,0,0, .025)' }}
                                placeholder="0"
                                onChange={this.setPaymentAmount}
                                onKeyPress={this.onPaymentKeyPress}
                                required
                            />
                            <InputGroup.Append>
                                <InputGroup.Text style={{ fontSize: '1.2rem', backgroundColor: "#fcfcfc" }}>.00</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                        <fieldset className="sq-fieldset">
                            <div style={{ position: 'relative' }}>
                                <CreditCardNumberInput />
                            </div>

                            <div className="sq-form-third">
                                <CreditCardExpirationDateInput />
                            </div>

                            <div className="sq-form-third">
                                <CreditCardPostalCodeInput />
                            </div>

                            <div className="sq-form-third">
                                <CreditCardCVVInput />
                            </div>
                        </fieldset>

                        <div style={{ marginTop: '-1rem' }}>
                            <CreditCardSubmitButton>
                                Pay $<span style={{ marginLeft: '.1rem' }}>{this.state.paymentAmount}.00</span>
                            </CreditCardSubmitButton>
                        </div>

                        <div style={{ width: '17.5rem', margin: 'auto' }}>
                            <Image src={SecurePayments} style={{ width: '100%', marginTop: '2.5rem' }}></Image>
                        </div>
                    </SquarePaymentForm>

                    <div className="sq-error-message">
                        {this.state.errorMessages.map(errorMessage =>
                            <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default SquareSpace;