import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Home from './components/home';
import Login from './components/login';
import Contact from './components/contact';
import IndividualBonds from './components/calculators/savings-bond-calculator-free';
import OnlineSpreadsheet from './components/calculators/savings-bond-calculator';
import ForgotPassword from './components/forgot-password';
import PasswordReset from './components/password-reset';
import Tutorial from './components/tutorial';
import SquareSpace from './components/checkout';
import AccountOptions from './components/account-options';
import TaxCalculator from './components/calculators/savings-bond-tax-calculator';
import MedicalCoding from './components/medical-coding/mc';
import logo from './images/bond-logo.png';
import setClass from './setClassName';
import "./styles/nav.css";
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.outerWidth
        };

        this.setNavbarClass = this.setNavbarClass.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (window.outerWidth <= 0) { this.setState({ windowWidth: window.innerWidth }) }
    }

    setNavbarClass() {
        let currentPage = window.location.pathname

        if (currentPage === '/') {
            return 'nav-background-home'
        }
        else {
            return 'nav-background'
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Navbar className={this.setNavbarClass()}>
                    <Navbar.Brand className={setClass('brand')}>
                        <img
                            src={logo}
                            alt="SAVINGS BOND PRICING TOOL"
                            className={setClass('brand-img')}
                        />
                    </Navbar.Brand>
                    <Nav.Link className={setClass('bond-nav-link')} href="/">Home</Nav.Link>
                    <Nav.Link className={setClass('bond-nav-link')} href="/tutorial">Tutorial</Nav.Link>
                    <NavDropdown className={setClass('bond-nav-link')} title="Calculators">
                        <NavDropdown.Item style={{ fontSize: '.85rem' }} href="/calculators/savings-bond-calculator">Savings Bond Calculator</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item style={{ fontSize: '.85rem' }} href="/calculators/savings-bond-calculator-free">Savings Bond Calculator Free (Limited Features) </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item style={{ fontSize: '.85rem' }} href="/calculators/savings-bond-tax-calculator">Savings Bond Tax Calculator</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className={setClass('bond-nav-link')} href="/contact">Contact</Nav.Link>
                </Navbar>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/tutorial' component={Tutorial} />
                    <Route exact path='/calculators/savings-bond-calculator-free' component={IndividualBonds} />
                    <Route exact path='/calculators/savings-bond-wizard' component={OnlineSpreadsheet} />
                    <Route exact path='/calculators/savings-bond-calculator' component={OnlineSpreadsheet} />
                    <Route exact path='/calculators/savings-bond-tax-calculator' component={TaxCalculator} />
                    <Route exact path='/contact' component={Contact} />
                    <Route exact path='/register' component={Login} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/checkout' component={SquareSpace} />
                    <Route exact path='/account-options' component={AccountOptions} />
                    <Route exact path='/forgot-password' component={ForgotPassword} />
                    <Route exact path='/password-reset' component={PasswordReset} />
                    <Route exact path='/medical-coding' component={MedicalCoding} />
                </Switch>
            </BrowserRouter>
        )
    }
}

export default App;