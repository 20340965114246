import React, { Component } from 'react';
import LogoutLink from '../components/logout-link';
import Cookies from 'universal-cookie';
import Alert from 'react-bootstrap/Alert';
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Logo from '../images/bond-logo.png';
import '../styles/login.css';

const cookies = new Cookies();

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: window.location.pathname,
            message: null,
            showAlert: false,
        };

        this.handleResize = this.handleResize.bind(this)
        this.submitForgotPassword = this.submitForgotPassword.bind(this)
    }

    handleResize() {
        setTimeout(() => {
            if (window.outerWidth > 0 && window.outerHeight > 0) {
                this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight })
            }
            else {
                this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
            }
        }, 25);
    }

    componentDidMount() {
        cookies.remove('forgottenCookie')

        window.addEventListener("resize", this.handleResize);

        if (window.outerWidth > 0 && window.outerHeight > 0) {
            this.setState({ windowWidth: window.outerWidth })
        }
    }

    submitForgotPassword(e) {
        e.preventDefault();

        let userEmail = e.target.forgotEmail.value

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify({ 'email': userEmail })
        };

        // http://localhost:5000/validate-user
        // https://secure.savingsbondsolutions.com/validate-user

        fetch('https://secure.savingsbondsolutions.com/validate-user', requestOptions)
            .then(response => response.json())
            .then(response => {
                if (response['result']) {
                    const cookies = new Cookies();

                    cookies.set('forgottenCookie', userEmail, { path: '/', expires: new Date(Date.now() + (60000 * 5)) });

                    this.setState({ showAlert: true, message: { 'text': 'Sent! If you don\'t see it, check your junk or all mail folder.', 'alert': 'success' } })
                    setTimeout(() => { this.setState({ showAlert: false }); }, 7500);

                    // http://localhost:5000/forgot-password
                    // https://secure.savingsbondsolutions.com/forgot-password

                    fetch('https://secure.savingsbondsolutions.com/forgot-password', requestOptions)
                }
                else {
                    this.setState({ showAlert: true, message: { 'text': 'We cannot find this account in our records.', 'alert': 'danger' } })
                    setTimeout(() => { this.setState({ showAlert: false }); }, 5000);
                }
            });
    }


    render() {
        const { message, showAlert, windowWidth, currentPage } = this.state

        return (
            <React.Fragment>
                <LogoutLink windowWidth={windowWidth} currentPage={currentPage} />
                <div style={{ marginTop: '2rem' }}>
                    {message ?
                        <Alert variant={message['alert']} className={showAlert ? 'alert-show' : 'alert-hidden'}>{message['text']}</Alert>
                        :
                        <Alert className={'alert-hidden'}>Placeholder</Alert>
                    }
                </div>
                <Form id="forgot-password-form" onSubmit={this.submitForgotPassword} style={{ width: '22.5rem', padding: '2rem', margin: 'auto', marginTop: '2rem', border: '1px solid #dee2e6', borderRadius: '.25rem' }}>
                    <h5 style={{ fontSize: '1.25rem', textAlign: 'center', marginBottom: '2rem' }}>
                        <Image style={{ float: 'left', width: '1.5rem', marginTop: '-.25rem' }} src={Logo}></Image>
                        FORGOT PASSWORD
                        <Image style={{ float: 'right', width: '1.5rem', marginTop: '-.25rem' }} src={Logo}></Image>
                    </h5>
                    <Form.Group style={{ position: 'relative' }}>
                        <Form.Label>Email Address:</Form.Label>
                        <Form.Control
                            name="forgotEmail"
                            type="email"
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        style={{ width: '100%', fontSize: '.85rem', fontWeight: 'bold' }}>
                        Send
                </Button>
                </Form>
            </React.Fragment>
        );
    }
}

export default ForgotPassword;