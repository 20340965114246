import React, { Component } from 'react';
import setClass from '../setClassName';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Cookies from 'universal-cookie';
import GearIcon from '../images/gear-icon.png';
import '../styles/nav.css';

const cookies = new Cookies();

class LogoutLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: window.location.pathname,
            showWelcome: false,
            userText: null,
            isLoggedIn: false,
            userName: null
        };

        this.handleLogout = this.handleLogout.bind(this)
    }

    componentDidMount() {

        if (cookies.get('inCookie') === "in") {
            this.setState({ isLoggedIn: true });
        }
        else {
            this.setState({ isLoggedIn: false });
        }
    }

    handleLogout() {
        cookies.remove('inCookie', { path: '/' });
        cookies.remove('userFullName', { path: '/' });
        cookies.remove('userName', { path: '/' });
        cookies.remove('userEmail', { path: '/' });
        cookies.remove('userType', { path: '/' });

        this.setState({ isLoggedIn: false });

        window.location.replace(this.state.currentPage);
    }

    render() {
        const { currentPage } = this.state
        const { windowWidth, isLoggedIn, hasFullAccess } = this.props

        return (
            <React.Fragment>
                {isLoggedIn ?
                    <div style={{ position: 'absolute', top: '1rem', right: 0, width: '100%' }}>
                        <div style={{ position: 'relative', top: 0, right: 0, width: '65%', minWidth: windowWidth * .7, margin: 'auto', textAlign: 'right' }}>
                            {hasFullAccess ?
                                <React.Fragment>
                                    <Button variant={"link"} style={{ paddingRight: 0 }} className={"login-logout-link " + setClass('bond-nav-link')}>{cookies.get('userName')}</Button>
                                    <span><Image src={GearIcon} style={{ width: '1.5rem', marginTop: '-.1rem', marginLeft: '.25rem' }}></Image></span>
                                </React.Fragment>
                                : <Button variant={"link"} style={{ paddingRight: 0 }} className={"login-logout-link " + setClass('bond-nav-link')} onClick={() => window.location.href = "/account-options"}>Get Full Access</Button>}
                        </div>
                    </div>
                    :
                    <div style={{ position: 'absolute', top: '1rem', right: 0, width: '100%' }}>
                        <div style={{ position: 'relative', top: 0, right: 0, width: '65%', minWidth: windowWidth * .7, margin: 'auto', textAlign: 'right' }}>
                            <Button variant={"link"} className={"login-logout-link " + setClass('bond-nav-link')} href="/register">Register</Button>
                            <Button variant={"link"} style={{ paddingRight: 0 }} className={"login-logout-link " + setClass('bond-nav-link')} href="/login" onClick={currentPage.includes("savings-bond-calculator") ? () => localStorage.setItem("savedBonds", JSON.stringify(this.props.savedBonds())) : null}>Login</Button>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default LogoutLink;