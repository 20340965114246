import React, { Component } from 'react';
import LogoutLink from '../components/logout-link';
import bcrypt from 'bcryptjs';
import Cookies from 'universal-cookie';
import Alert from 'react-bootstrap/Alert';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import GreenCheck from "../images/green-check.png";
import RedCross from "../images/red-cross.png";
import Logo from '../images/bond-logo.png';
import '../styles/login.css';

const cookies = new Cookies();

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: window.location.pathname,
            password: { "check": false, "cross": false, "error": null },
            repeat: { "check": false, "cross": false, "error": null },
            pwd: "",
            rpt: "",
            hash: null,
            message: null,
            showAlert: false
        };

        this.handleResize = this.handleResize.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onChangeRepeat = this.onChangeRepeat.bind(this)
        this.setMessage = this.setMessage.bind(this)
        this.submitPasswordReset = this.submitPasswordReset.bind(this)
    }

    handleResize() {
        setTimeout(() => {
            if (window.outerWidth > 0 && window.outerHeight > 0) {
                this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight })
            }
            else {
                this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
            }
        }, 25);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (window.outerWidth > 0 && window.outerHeight > 0) {
            this.setState({ windowWidth: window.outerWidth })
        }
    }

    setMessage(msgText, msgAlert, timeout) {
        this.setState({ showAlert: true, message: { 'text': msgText, 'alert': msgAlert } })

        setTimeout(() => { this.setState({ showAlert: false }); }, timeout);

    }

    encryptPassword(password) {
        bcrypt.genSalt(10, function (err, salt) {
            bcrypt.hash(password, salt, function (err, hash) {
                this.setState({ hash: hash })
            }.bind(this));
        }.bind(this));
    }

    parsePassword(password) {
        let pwdSplit = password.split("")

        let letters = []
        let numbers = []

        for (let i in pwdSplit) {
            let char = pwdSplit[i]

            if (!isNaN(char)) {
                numbers.push(char)
            }
            else {
                letters.push(char)
            }
        }

        if (letters.length >= 5 && numbers.length > 0) {
            return true
        }
        else {
            return false
        }
    }

    onChangePassword(e) {
        const { rpt } = this.state
        let password = e.target.value

        let valid = this.parsePassword(password)

        if (password.length === 0) {

            this.setState({ password: { "check": false, "cross": false, "error": null } })

            if (rpt.length > 0) {
                this.setState({ repeat: { "check": false, "cross": true, "error": "Passwords do not match." } })
            }
        }
        else {
            if (valid) {
                this.setState({ password: { "check": true, "cross": false, "error": null } })

                if (rpt.length === 0) {
                    this.setState({ repeat: { "check": false, "cross": false, "error": null } })
                }
                else if (rpt === password) {
                    this.setState({ repeat: { "check": true, "cross": false, "error": null } })
                }
                else {
                    this.setState({ repeat: { "check": false, "cross": true, "error": "Passwords do not match." } })
                }
            }
            else {
                this.setState({ password: { "check": false, "cross": true, "error": "Must contain at least five letters and a number." } })
            }
        }

        this.setState({ pwd: password })
    }

    onChangeRepeat(e) {
        const { pwd } = this.state

        let repeat = e.target.value

        if (repeat.length === 0) {
            this.setState({ repeat: { "check": false, "cross": false, "error": null } })
        }
        else {
            if (pwd === repeat) {
                this.setState({ repeat: { "check": true, "cross": false, "error": null } })

                this.encryptPassword(pwd)
            }
            else {
                this.setState({ repeat: { "check": false, "cross": true, "error": "Passwords do not match." } })
            }
        }

        this.setState({ rpt: repeat })
    }

    submitPasswordReset(e) {
        e.preventDefault();

        const { password, repeat, hash } = this.state

        let rPassword = document.getElementsByName("resetPassword")[0]
        let rRepeat = document.getElementsByName("resetRepeat")[0]

        if (rPassword.value.length === 0 && rRepeat.value.length === 0) {
            this.setMessage('Please enter a password before submitting.', 'warning', 3000)
        }
        else if (password['check'] && repeat['check']) {
            let resetAccount = { 'email': cookies.get('forgottenCookie'), 'password': hash }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({ resetAccount })
            };

            // http://localhost:5000/reset-password
            // https://secure.savingsbondsolutions.com/reset-password

            fetch('https://secure.savingsbondsolutions.com/reset-password', requestOptions).then(window.location.replace("/login"));
        }
        else {
            this.setState({
                message: { 'text': 'Your passwords do not match.', 'alert': 'danger' },
                showAlert: true
            });

            setTimeout(() => { this.setState({ showAlert: false }); }, 3000);
        }
    }

    render() {
        const { password, repeat, message, showAlert, windowWidth, currentPage } = this.state

        return (
            <React.Fragment>
                <LogoutLink windowWidth={windowWidth} currentPage={currentPage} />
                <div style={{ marginTop: '2rem' }}>
                    {message ?
                        <Alert variant={message['alert']} className={showAlert ? 'alert-show' : 'alert-hidden'}>{message['text']}</Alert>
                        :
                        <Alert className={'alert-hidden'}>Placeholder</Alert>
                    }
                </div>
                <Form id="password-reset-form" onSubmit={this.submitPasswordReset} style={{ width: '22.5rem', padding: '2rem', margin: 'auto', marginTop: '2rem', border: '1px solid #dee2e6', borderRadius: '.25rem' }}>
                    <h5 style={{ fontSize: '1.25rem', textAlign: 'center', marginBottom: '2rem' }}>
                        <Image style={{ float: 'left', width: '1.5rem', marginTop: '-.25rem' }} src={Logo}></Image>
                        PASSWORD RESET
                        <Image style={{ float: 'right', width: '1.5rem', marginTop: '-.25rem' }} src={Logo}></Image>
                    </h5>
                    <Form.Group style={{ position: 'relative' }}>
                        <Form.Label>New Password:</Form.Label>
                        <Form.Control
                            name="resetPassword"
                            type="password"
                            onChange={this.onChangePassword}
                        />
                        {password["check"] ? <Image className="register-checkmark" src={GreenCheck} alt="Green Check Email"></Image> : null}
                        {password["cross"] ? <Image className="register-cross" src={RedCross} alt="Red Cross Email"></Image> : null}
                        {password["error"] ? <Form.Text style={{ color: 'red', textAlign: 'center' }}>{password["error"]}</Form.Text> : null}
                    </Form.Group>
                    <Form.Group style={{ position: 'relative' }}>
                        <Form.Label>Repeat Password:</Form.Label>
                        <Form.Control
                            name="resetRepeat"
                            type="password"
                            onChange={this.onChangeRepeat}
                        />
                        {repeat["check"] ? <Image className="register-checkmark" src={GreenCheck} alt="Green Check Email"></Image> : null}
                        {repeat["cross"] ? <Image className="register-cross" src={RedCross} alt="Red Cross Email"></Image> : null}
                        {repeat["error"] ? <Form.Text style={{ color: 'red', textAlign: 'center' }}>{repeat["error"]}</Form.Text> : null}
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        style={{ width: '100%', fontSize: '.85rem', fontWeight: 'bold', marginTop: '.5rem' }}>
                        Update Password
                </Button>
                </Form>
            </React.Fragment>
        );
    }
}

export default PasswordReset;